<div class="form-container" style="margin-top: 20px">
    <div class="form-box template-email">
        <h3 style="text-align:center">Section 4: Standardisation aspects and knowledge</h3>

        <div class="panel-group" id="accordion_1st">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h5 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion_1st">{{ data==='A' ? 'My research project is within the area of basic research (TRL 1-3).'
                             : data==='B' ? 'My research project is/will be within the area of applied research (TRL 4-5).'
                                : 'My research project is/will be within the area of technology development and implementation (TRL 6-9).' }}</a>
                    </h5>
                </div>
                <div id="collapsedString" class="panel-collapse collapsed">
                    <div class="panel-body">
                        <app-ckeditor-form>

                        </app-ckeditor-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
