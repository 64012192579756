import { Component, ComponentRef, OnInit,  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { FormService } from '../form.service';

@Component({
  selector: 'first-step',
  templateUrl: './first-step.component.html',
  styleUrls: ['../advice-form.component.scss']
})
export class FirstStepComponent implements OnInit {

  selection: any;
  alertMessage: string = '';
  alertType: Alert = Alert.SUCCESS;

  constructor(private fb: FormBuilder, private router: Router, private formService: FormService,) {
  }

  ngOnInit() {
    console.log('FirstStepComponent');
    if (this.formService.canNavigate) {
      console.log('statement');
    } else if (history.state.data == 'ok') {
      console.log('ok');
      this.formService.canNavigate = true;
    }else{
      console.log('no data');
      this.router.navigateByUrl('SOT/(advice-form:zero-step)');
    }
    this.selection = document.getElementById('selection');
    console.log(this.selection);
    this.selection.addEventListener('change', (event: any) => {
      console.log('change');
      console.log(this.selection.value);
    });
  }

  submitForm() {
    console.log('submitForm');
    console.log('Selected statement:',this.selection.value);
    let value : NavigationEnd = this.selection.value;
    this.router.navigateByUrl('SOT/(advice-form:second-step)', { state: { data: value } });
  }
  
}