import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable} from 'rxjs';

  
@Injectable({
  providedIn: 'root'
})
export class SopService {
  private url =  '/api/v1/';
  //private url = '/api/v1'
  
   
  constructor(private httpClient: HttpClient) { }
  
  getAll(type: string): Observable<any []>{
    const headers = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any []>( this.url + 'sopstatus/' + type, {headers : headers}); 
  }

  getByID(type: string, id: number): Observable<any>{
    const headers = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.get<any>( this.url + 'sopstatus/' + type +'/'+id, {headers : headers}); 
  }
  
  setSopStatus(type: string, id: number, status: string, email: string = '', name_surname: string = ''): Observable<string>{
    const headers = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    
    return this.httpClient.patch<string>( this.url + 'sopstatus/epe', {"id": id, "status": status, "email": email, "name_surname": name_surname, "type": type}, {headers : headers});
  }

  getMaterials(): Observable<{id: number, title: string, name :string}[]>{

    return this.httpClient.get<{id: number, title: string, name :string}[]>(this.url +'materials')

  }
}