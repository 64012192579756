import {Component, OnInit, ViewChild} from '@angular/core';
import {ApexChart, ApexNonAxisChartSeries, ChartComponent, ApexTitleSubtitle} from 'ng-apexcharts';
import * as Highcharts from 'highcharts/highmaps';
import worldMap from "@highcharts/map-collection/custom/world-eckert3-highres.geo.json";
import {Logger} from 'app/utilities/logger';
import {SopService} from 'app/services/sop.service';
import {countries} from 'app/shared/store/contry-data-store';
import {forEach} from 'lodash';
import {Alert} from 'app/shared/alerts/simple-message/simple-message.component';
import {SubmissionMongo} from 'app/models/submission';
import {SubmissionsService} from 'app/services/submissions.service';
import Swal from "sweetalert2";
import {AngularCsv} from "angular-csv-ext/dist/Angular-csv";
import {FormService} from "../../advice-form/form.service";

@Component({
    selector: 'statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss', '../strl.component.scss']
})
export class StatisticsComponent implements OnInit {

    loading: boolean = true;
    submissions: any[] | null = []

    applications: number = 0;
    trls: string[] = ['Basic Research<br/>(TRL 1-3)', 'Applied Research<br/>(TRL 4-5)', 'Development &<br/>Implementation (TRL 6-9)'];
    trlsCount: number[] = [0, 0, 0];

    submitters: string[] = ['Epe', 'Project', 'Guest', 'Other'];
    submittersCount: number[] = [0, 0, 0, 0];

    updateFlag = false;

    showAlert: boolean = false
    alertMessage: string = ''
    alertType: Alert = Alert.SUCCESS

    public countries: any = countries;

    trlChart = {
        series: <ApexNonAxisChartSeries>[0, 0, 0],
        chart: <ApexChart>{
            type: 'donut',
            toolbar: {
                show: true
            }
        },
        labels: this.trls,
        chartTitle: <ApexTitleSubtitle>{
            text: 'TRL: Categories of Scale and Definitions',
            align: 'left',
        }
    };

    submittersChart = {
        series: <ApexNonAxisChartSeries>[0, 0, 0, 0],
        chart: <ApexChart>{
            type: 'donut',
            toolbar: {
                show: true
            }
        },
        labels: this.submitters,
        chartTitle: <ApexTitleSubtitle>{
            text: 'Submitters',
            align: 'left',
        }
    };


    Highcharts: typeof Highcharts = Highcharts;
    chartConstructor = "mapChart";
    chartData = [{code3: "ABW", z: 105}, {code3: "AFG", z: 35530}];

    chartOptions: Highcharts.Options = {
        chart: {
            map: worldMap
        },
        title: {
            text: "<b>Geographical</b> category of projects"
        },
        mapNavigation: {
            enabled: true,
            buttonOptions: {
                alignTo: "spacingBox"
            }
        },
        legend: {
            enabled: true
        },
        colorAxis: {
            min: 0
        },
        series: [
            {
                type: "map",
                name: "Random data",
                states: {
                    hover: {
                        color: "#BADA55"
                    }
                },
                dataLabels: {
                    enabled: true,
                    format: "{point.name}"
                },
                allAreas: false,
                data: []
            }
        ]
    };

    p: number = 1;
    itemsPerPage: number = 10;

    headerRow: string[] = [
        'Grants Agreement Number',
        'Project Acronym',
        'Full Project Name',
        'Project Website',
        'TRL',
        'SActions',
        'User Roles',
        'Name',
        'Surname',
        'Phone',
        'Organisation',
        'Organisation Type',
        'Gender',
        'Country',
    ]

    constructor(
        private formService: FormService,
        public sopService: SopService,
        private submissionService: SubmissionsService
    ) {
    }

    ngOnInit(): void {
        this.submissionService.getAll().subscribe(submissions => {
            this.submissions = submissions
            this.loadCharts()
            this.loading = false
        })
    }

    // getActualDuration(meeting_form_1: string, meeting_form_5: string){
    //   const date1 = new Date(meeting_form_5);
    //   const date2 = new Date(meeting_form_1);
    //   const timeDifference = date1.getTime() - date2.getTime();
    //   const daysDifference = Math.abs(Math.floor(timeDifference / (24 * 60 * 60 * 1000)));
    //   return daysDifference
    // }


    loadCharts() {
        this.applications = this.submissions?.length || 0;
        this.countTrls()
        // this.countCallTopics()
        // this.callTopicsChart.series = this.callTopicsCount;
        this.loadCountries()
        this.countSubmitters()
        // this.countServiceDeliveryStatus()
        // this.serviceDeliveryStatusChart.series = this.serviceDeliveryStatusCount;

    }


    countTrls() {
        this.submissions?.forEach(submission => {
            if (submission.statement == 'A') {
                this.trlsCount[0] += 1
            } else if (submission.statement == 'B') {
                this.trlsCount[1] += 1
            } else if (submission.statement == 'C') {
                this.trlsCount[2] += 1
            }
        });
        this.trlChart.series = this.trlsCount;
    }

    countSubmitters() {
        this.submissions?.forEach(submission => {
            if (submission.userRoles?.includes('epe')) {
                this.submittersCount[0] += 1
            } else if (submission.userRoles?.includes('project')) {
                this.submittersCount[1] += 1
            } else if (submission.userRoles?.includes('guest')) {
                this.submittersCount[2] += 1
            } else {
                this.submittersCount[3] += 1
            }
        });
        this.submittersChart.series = this.submittersCount;
    }


    // countCallTopics(){
    //   this.projects?.forEach(project => {
    //     for (let key in project.topics){
    //       if(key == 'Health'){
    //         this.callTopicsCount[0] += 1
    //       } else if(key == 'Resilience'){
    //         this.callTopicsCount[1] += 1
    //       } else if(key == 'Sustainable digitalisation'){
    //         this.callTopicsCount[2] += 1
    //       } else if(key == 'Green transition in Europe'){
    //         this.callTopicsCount[3] += 1
    //       } else if(key == 'Smart Cities and Circular Economy in Buildings'){
    //         this.callTopicsCount[4] += 1
    //       }
    //     }
    //   });
    // }

    // countServiceDeliveryStatus(){
    //   // this.serviceDeliveryStatusCount[1] = this.associations?.filter(association => association.meeting === 7).length || 0;
    //   this.serviceDeliveryStatusCount[0] = this.associations?.filter(association => association.association_status === 'approved').length || 0;
    //   this.serviceDeliveryStatusCount[1] = this.associations?.filter(association => association.association_status === 'completed').length || 0;
    //   this.serviceDeliveryStatusCount[2] = this.projects!.length - this.associations!.length || 0;
    //   this.serviceDeliveryStatusCount[3] = this.associations?.filter(association => association.association_status === 'closed').length || 0;
    // }

    loadCountries() {
        forEach(this.submissions, (submission) => {
            if (submission.country) {
                let country
                if (country = this.countries.filter((country: any) => country.name == submission.country)) {
                    this.updateStateValue(country[0].code, country[0].value + 1)
                }
            }
        })
        let arrayData: any[] = [];
        forEach(this.countries, (country: any) => {
            arrayData.push([country.code.toLowerCase(), country.value])
        })
        this.chartOptions.series = [{
            type: "map",
            name: "Projects",
            states: {
                hover: {
                    color: "#BADA55"
                }
            },
            dataLabels: {
                enabled: true,
                format: "{point.name}"
            },
            allAreas: false,
            data: arrayData
        }]

        this.updateFlag = true;

    }


    updateStateValue(countryCode: string, newValue: number): void {
        let countryIndex = countries.findIndex((country) => country.code === countryCode);

        if (countryIndex !== -1) {
            countries[countryIndex].value = newValue;
            //console.log(`Updated value of ${countries[countryIndex].name} to ${newValue}`);
        } else {
            //console.log(`Country with code ${countryCode} not found.`);
        }
    }

    calculateRowIndex(indexOnPage: number): number {
        return (this.p - 1) * this.itemsPerPage + indexOnPage + 1;
    }


    alertConfirmation(row: any) {
        let id = row._id['$oid']
        Swal.fire({
            title: "Are you sure you want to delete this application?",
            text: "This process is irreversible.",
            showDenyButton: true,
            showConfirmButton: false,
            showCancelButton: true,
            denyButtonText: "Yes, delete it!",
            cancelButtonText: "No, let me think",
        }).then((result) => {
            if (result.isDenied) {
                this.submissionService.deleteSubmission(id).subscribe((message) => {
                    if (message['message'] == 'Submission deleted!') {
                        this.submissions = this.submissions?.filter(submission => submission._id['$oid'] !== id) || []
                        this.loadCharts()
                        Swal.fire({
                            icon: "success",
                            title: "Your application has been deleted",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Something went wrong",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                });


            }
        });
    }

    // openNewTab(url: any){
    //   window.open(url)
    // }

    // calculateRowIndex(indexOnPage: number): number {
    //   return (this.p - 1) * this.itemsPerPage + indexOnPage + 1;
    // }


    addAlert(message: string, type: Alert) {
        this.alertMessage = message
        this.alertType = type
        this.showAlert = true
    }

    getCsvOptions(title: string, headerRow: string[]) {
        return {
            fieldSeparator: ';',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            title: title,
            useBom: true,
            noDownload: false,
            headers: headerRow
        };
    }

    exportCSV() {
        const submissionsPayload = this.submissions?.map((submission: SubmissionMongo) => {
            return {
                'Grants Agreement Number': submission.grants_agreement_number,
                'Project Acronym': submission.project_acronym,
                'Full Project Name': submission.full_project_name,
                'Project Website': submission.project_website,
                'TRL': submission.statement == 'A' ? 'Basic Research (TRL 1-3)' : submission.statement == 'B' ? 'Applied Research (TRL 4-5)' : 'Development & Implementation (TRL 6-9)',
                'SActions': this.stripHtmlTags(this.formService.getSActionsIdByMatrixKeys(submission.answers).map((sActionId) => this.formService.getActionById(sActionId)).join(', ')),
                'User Roles': submission.userRoles?.includes('epe') ? 'Epe' : submission.userRoles?.includes('project') ? 'Project' : submission.userRoles?.includes('guest') ? 'Guest' : 'Other',
                'Name': submission.name,
                'Surname': submission.surname,
                'Phone': submission.phone,
                'Organisation': submission.organisation,
                'Organisation Type': submission.organisation_type,
                'Gender': submission.gender,
                'Country': submission.country || 'N/A'
            }
        });
        console.log(submissionsPayload, 'qui')
        const csvOptions = this.getCsvOptions('SOT Submissions', this.headerRow);

        new AngularCsv(submissionsPayload, 'SOT-Submissions-HSBooster', csvOptions);
    }

    getSActionsByAnswerId(answers: string[]): string {
        return this.stripHtmlTags(this.formService.getSActionsIdByMatrixKeys(answers).map((sActionId) => this.formService.getActionById(sActionId)).join(', '))
    }

    stripHtmlTags(html: string): string {
        return html.replace(/<a\b[^>]*>(.*?)<\/a>/g, '$1');
    }
}
