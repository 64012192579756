
<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
        <div class="u-layout pb-2">
          <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
              <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1">
                <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Standards Orientation Tool</h1>
              </div>
            </div>
            <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
              <div class="u-container-layout u-container-layout-2">
                <h2 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs details">Please select a section in the sidebar</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
