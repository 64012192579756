import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { SopService } from 'app/services/sop.service';
import { countries } from 'app/shared/store/contry-data-store';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { error } from 'console';
import { FormService } from '../form.service';

@Component({
  selector: 'zero-step',
  templateUrl: './zero-step.component.html',
  styleUrls: ['../advice-form.component.scss']
})
export class ZeroStepComponent implements OnInit {
  
  loading: boolean = true;
  public countries:any = countries;
  form!: FormGroup;
  epes:any = null;
  projects:any = null;

  
  defaultAction: number[] = [2, 7, 11, 17, 23];
  expandedAction: { [key: number]: boolean } = {};

  constructor(
    public auth: SessionStorage,
    private cache: SopStorage,
    private service: SopService,
    private router: Router,
    private formService: FormService,
    ) { }

  ngOnInit(): void {
    this.epes = this.cache.getEpes();
    this.projects = this.cache.getProjects();

    this.form = new FormGroup({
      title: new FormControl(''),
      name: new FormControl('', [Validators.required]),
      surname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^\+?[0-9\s]*$/)]),
      organisation: new FormControl('', [Validators.required]),
      organisation_type: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      grants_agreement_number: new FormControl('',[Validators.pattern("^[0-9]*$")]),
      project_acronym: new FormControl('',),
      full_project_name: new FormControl(''),
      project_website: new FormControl(''),
    });

    this.auth.auth$.subscribe({
      next: then => {
        // this.auth.setRoles(['project']);
        // this.auth.setUsername('uoteo');

        // this.auth.setRoles(['epe']);
        // this.auth.setUsername("crabbypochardha");

        this.formService.userRoles = this.auth.roles;

        if (!this.auth.isGuest()){
          let user
          if(this.auth.isEpe()){
            if(!this.epes){
              this.service.getAll('epe').subscribe(
                then => {
                  this.epes = then;
                  user = this.epes.filter((epe: { username: any; }) => epe.username == this.auth.getUsername());
                  this.cache.setEpes(this.epes);
                  this.setFormValues(user[0], false);
                  this.loading = false;
                }
              )
            } else {
              user = this.epes.filter((epe: { username: any; }) => epe.username == this.auth.getUsername());
              this.setFormValues(user[0], false);
              this.loading = false;
            }
          } else if (this.auth.isProject()){
            if(!this.projects){
              this.service.getAll('project').subscribe(
                then => {
                  this.projects = then;
                  user = this.projects.filter((project: { username: any; }) => project.username == this.auth.getUsername());
                  this.cache.setProjects(this.projects);
                  this.setFormValues(user[0], true);
                  this.loading = false;
                }
              )
            } else {
              user = this.projects.filter((project: { username: any; }) => project.username == this.auth.getUsername());
              this.setFormValues(user[0], true);
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
          

        } else {
          this.loading = false;
        }
      },
      error: err => {
        console.log('error');
        this.loading = false;
      }
    })
  }

  setFormValues(user: any, isProject: boolean){
    if (isProject){
      if(user?.grant_agreement){
        this.form.controls['grants_agreement_number'].setValue(user.grant_agreement)
      }
      if(user?.project_acronym){
        this.form.controls['project_acronym'].setValue(user.project_acronym)
      }
      if(user?.full_project_name){
        this.form.controls['full_project_name'].setValue(user.full_project_name)
      }
      if(user?.project_website){
        this.form.controls['project_website'].setValue(user.project_website)
      }
    }

    if (user?.title){
      this.form.controls['title'].setValue(user.title)
    }
    if (user?.name){
      this.form.controls['name'].setValue(user.name)
    }
    if (user?.surname){
      this.form.controls['surname'].setValue(user.surname)
    }
    if (user?.phone_number){
      this.form.controls['phone'].setValue(user.phone_number)
    }
    if (user?.organisation){
      this.form.controls['organisation'].setValue(user.organisation)
    }
    if (user?.organisation_type){
      this.form.controls['organisation_type'].setValue(user.organisation_type)
    }
    if (user?.gender){
      this.form.controls['gender'].setValue(user.gender)
    }
    if (user?.country){
      this.form.controls['country'].setValue(user.country)
    }


  }


  onSubmit() {
    console.log('Form submitted:', this.form);
    if (this.form.valid) {
      const formData = this.form.value;
      console.log('Form submitted:', formData);
      let value = 'ok';
      this.formService.setFormValue(this.form);
      this.router.navigateByUrl('SOT/(advice-form:first-step)', { state: { data: value } });
    } else {
      console.log('Form errors:', this.form);
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      } else {
        control!.markAsTouched({ onlySelf: true });
      }
    });
  }

  getActionById(id: number){
    return this.formService.getActionById(id);
  }

  expand(action: number): void {
    this.expandedAction[action] = !this.expandedAction[action];
  }

  isExpanded(action: number): boolean {
    return this.expandedAction[action];
  }

}
