<footer class="footer">
    <nav>
        <ul>
            <li>
                <img src="../../../assets/img/europe_funded.png" alt="logo" width="36px" height="37px">
            </li>
            <li>
                <p>
                    HSbooster.eu has received funding from the EU’s Horizon Europe research and innovation programme under Grant Agreement no. 101058391
                </p>
            </li>
        </ul>
    </nav>
</footer>
