<div *ngIf="!read_only" class="form-container">
    <div class="form-box template-email">

        <p *ngIf="toSubmit">Remember to fill in the activity report in all its sections by the end of the service</p>
        
        <div class="panel-group" id="accordion_report">
            <div class="panel panel-default">
              <div class="panel-heading">
                <h5 class="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapse_report">{{title}}</a>
                </h5>
              </div>
              <div id="collapse_report" class="panel-collapse collapse">
                <div class="panel-body">
                    <div *ngFor="let question of questions">
                        <open-question *ngIf="isOpenQuestion(question)" [type]="'report'" [question]="getQuestionKey(question)" [editor]="getQuestion(question)" [description]="getQuestionDescription(question)"></open-question>
                        <tick-box *ngIf="!isOpenQuestion(question)" [fields]="question" [type]="'report'" [description]="getQuestionDescription(question)"></tick-box>
                    </div>
                    <button *ngIf="toSubmit" (click)="confirm()" class="u-btn sop-btn">Save</button>
                </div>
              </div>
            </div>
          </div> 
    </div>
</div>

<div *ngIf="read_only" class="form-container">
  <div class="form-box template-email">
      <h3 style="text-align:center">Activity Report Summary</h3>
      <p *ngIf="!toSubmit">The service is finished, you can see below what is being submitted as Activity Report</p>
      <div class="panel-group" id="accordion_report">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h5 class="panel-title">
                <a data-toggle="collapse" data-parent="#accordion" href="#collapse_report">{{title}}</a>
              </h5>
            </div>
            <div id="collapse_report" class="panel-collapse collapse">
              <div class="panel-body">
                <div class="summary-box">
                  <div class="summary-row" *ngFor="let key of this.getKeys()">
                      <p><strong>{{key}}</strong></p>
                      <p>
                          <i *ngIf="summary[key]===true" class="fa fa-check"></i>
                          <span *ngIf="summary[key]!==true">
                              <div [innerHTML]="summary[key]"></div>
                          </span>
                      </p>
                  </div>
              </div>
              </div>
            </div>
          </div>
      
        </div> 

  </div>
</div>

<svg class="inline-svg">
  <symbol id="check" viewbox="0 0 12 10">
      <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
  </symbol>
</svg>
<style>
  .inline-svg {
      position: absolute;
      width: 0;
      height: 0;
      pointer-events: none;
      user-select: none;
  }
</style>




<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>