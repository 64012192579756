
<div class="sidebar-wrapper">
    <div class="logo">
        <a href="https://www.hsbooster.eu" class="simple-text">
            <div class="logo-img">
                <img src="/assets/img/logo.svg"/>
            </div>
        </a>
    </div>
    <ul class="nav responsive-nav">
        <li *ngIf="isMobileMenu()">
            <a class="dropdown-toggle" data-toggle="dropdown">
                <i class="fa fa-dashboard"></i>
				<p class="hidden-lg hidden-md">Dashboard</p>
            </a>
        </li>
        <li class="dropdown" *ngIf="isMobileMenu()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-globe"></i>
                    <b class="caret hidden-sm hidden-xs"></b>
                    <span class="notification hidden-sm hidden-xs">5</span>
					<p class="hidden-lg hidden-md">
						5 Notifications
						<b class="caret"></b>
					</p>
              </a>
              <ul class="dropdown-menu">
                <li><a href="#">Notification 1</a></li>
                <li><a href="#">Notification 2</a></li>
                <li><a href="#">Notification 3</a></li>
                <li><a href="#">Notification 4</a></li>
                <li><a href="#">Another notification</a></li>
              </ul>
        </li>
        <li *ngIf="isMobileMenu()">
           <a>
                <i class="fa fa-search"></i>
				<p class="hidden-lg hidden-md">Search</p>
            </a>
        </li>
        <li *ngIf="isMobileMenu()">
           <a href="">
               <p>Account</p>
            </a>
        </li>
        <li class="dropdown" *ngIf="isMobileMenu()">
              <a class="dropdown-toggle" data-toggle="dropdown">
                    <p>
    					Dropdown
    					<b class="caret"></b>
    				</p>

              </a>
              <ul class="dropdown-menu">
                <li><a href="#">Action</a></li>
                <li><a href="#">Another action</a></li>
                <li><a href="#">Something</a></li>
                <li><a href="#">Another action</a></li>
                <li><a href="#">Something</a></li>
                <li class="divider"></li>
                <li><a href="#">Separated link</a></li>
              </ul>
        </li>
        <li *ngIf="isMobileMenu()">
            <a>
                <p>Log out</p>
            </a>
        </li>
        <li class="separator hidden-lg hidden-md" *ngIf="isMobileMenu()"></li>
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}}">
            <a *ngIf="auth.isAuthorized(menuItem.roles)"  [routerLink]="[menuItem.path]">
                <i class="{{menuItem.icon}}"></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
