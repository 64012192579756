import { Component, OnInit } from '@angular/core';
import { FormService } from '../form.service';
import { Router } from '@angular/router';

@Component({
  selector: 'second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['../advice-form.component.scss']
})
export class SecondStepComponent implements OnInit {

  data: any;

  constructor(
    private formService: FormService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('SecondStepComponent');
    this.data = history.state.data;  
    if (this.data) {
      console.log(this.data);
      this.formService.setStatement(this.data);
    }else{
      console.log('no data');
      this.router.navigateByUrl('SOT/(advice-form:first-step)');
    }
  }

}
