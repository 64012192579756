import { SessionStorage } from "app/storage/session.storage";
import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { FormService } from "app/advice-form/form.service";
import { Router } from "@angular/router";


export interface Fields {
  [key: string]: string[];
}

@Component({
  selector: "tick-box2",
  template: `
    <form [formGroup]="form"></form>
    <div *ngFor="let question of questions; let i = index">
      <h4>{{ question }}</h4>
      <div [innerHTML]="description"></div>
      <form [formGroup]="form" class="ans">
        <div
          *ngFor="let answer of options[question]"
          [ngClass]="i != 0 ? 'ans-checkbox-rate' : 'ans-checkbox'"
        >
          <div>
            <input
              formArrayName="{{ question }}"
              (change)="onCheckboxChange($event, question)"
              [value]="answer"
              [checked]="isChosen(answer, question)"
              class="inp-cbx"
              id="{{ question + answer }}"
              type="checkbox"
            />
            <label
              class="cbx"
              style="display: flex;"
              for="{{ question + answer }}"
            >
              <span style="align-self: center;">
                <div style="width: 18px">
                  <svg width="12px" height="10px">
                    <use xlink:href="#check"></use>
                  </svg>
                </div>
              </span>
              <span>{{ answer }}</span>
            </label>
          </div>
        </div>
      </form>
    </div>
    <!--SVG Sprites-->
    <svg class="inline-svg">
      <symbol id="check" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>
    <button (click)="confirm()" class="u-btn sop-btn">Save</button>
  `,
  styles: [
    `
      .ans {
        display: flex;
        flex-wrap: wrap;
      }
      .ans-checkbox {
        flex: 100%;
      }
      .ans-checkbox-rate {
        flex: 100%;
      }
      .cbx {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        padding: 6px 8px;
        border-radius: 6px;
        overflow: hidden;
        transition: all 0.2s ease;
      }
      .cbx:hover {
        background: rgba(0, 119, 255, 0.06);
      }
      .cbx span {
        float: left;
        vertical-align: middle;
        transform: translate3d(0, 0, 0);
      }
      .cbx span:first-child {
        position: relative;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        transform: scale(1);
        border: 1px solid #cccfdb;
        transition: all 0.2s ease;
        box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
      }
      .cbx span:first-child svg {
        position: absolute;
        top: 3px;
        left: 2px;
        fill: none;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }
      .cbx span:last-child {
        padding-left: 8px;
        line-height: 18px;
      }
      .cbx:hover span:first-child {
        border-color: #07f;
      }
      .inp-cbx {
        position: absolute;
        visibility: hidden;
      }
      .inp-cbx:checked + .cbx span:first-child {
        background: #07f;
        border-color: #07f;
        animation: wave 0.4s ease;
      }
      .inp-cbx:checked + .cbx span:first-child svg {
        stroke-dashoffset: 0;
      }
      .inline-svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
      }
      @media screen and (max-width: 640px) {
        .cbx {
          width: 100%;
          margin-bottom: 4px;
          display: inline-block;
        }
      }
      @-moz-keyframes wave {
        50% {
          transform: scale(0.9);
        }
      }
      @-webkit-keyframes wave {
        50% {
          transform: scale(0.9);
        }
      }
      @-o-keyframes wave {
        50% {
          transform: scale(0.9);
        }
      }
      @keyframes wave {
        50% {
          transform: scale(0.9);
        }
      }
    `,
  ],
})
export class TickBoxComponent2 implements OnInit, AfterViewInit {
  questions: string[] = [];
  options: { [key: string]: string[] } = {};

  unique: boolean = false;
  rate: boolean = false;

  form: FormGroup = this.fb.group({});

  description: string =
    "Please select the option that best describes your project.";

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private router: Router
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.questions = this.formService.getQuestionByStatement();
    this.formService.getQuestionKeysByStatement().forEach((key) => {
      this.options[this.formService.question[key]] =
        this.formService.getOptionsByQuestionKey(key);
    });

    this.questions.forEach((question) => {
      this.form.addControl(question, new FormArray([]));
    });
  }

  onCheckboxChange(event: Event, question: string): void {
    const target = event.target as HTMLInputElement;
    const selectedAnswers = this.form.controls[question] as FormArray;
    if (this.questions.indexOf(question) == 0) selectedAnswers.clear();
    if (!target) return;
    if (target.checked) selectedAnswers.push(new FormControl(target.value));
    else {
      const index = selectedAnswers.controls.findIndex(
        (x) => x.value === target.value
      );
      selectedAnswers.removeAt(index);
    }
  }

  isChosen(answer: string, question: string): boolean {
    const selectedAnswers = this.form.controls[question] as FormArray;

    return selectedAnswers.controls.findIndex((x) => x.value === answer) > -1;
  }

  getField(question: string): string[] {
    return this.options[question];
  }

  confirm(): void {
    this.formService.setAnswers(this.form.value);
    this.router.navigateByUrl("SOT/(advice-form:third-step)");
  }
}
