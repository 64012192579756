import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SubmissionMongo } from 'app/models/submission';

@Injectable({
    providedIn: 'root'
})

export class SubmissionsService {
    private url = '/api/v1/submissions/';

    constructor(private httpClient: HttpClient) { }

    getAll(): Observable<any[]> {
        return this.httpClient.get<any[]>(this.url);
    }

    createSubmission(submission: any): Observable<any> {
        return this.httpClient.post<SubmissionMongo>(this.url, submission);
    }

    deleteSubmission(id: string): Observable<any> {
        return this.httpClient.delete<any>(this.url + id);
    }
}