import { Epe } from 'app/models/epe';
import { Project } from 'app/models/project';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';
import { SopStorage } from 'app/storage/sop.storage';
import { SopService } from 'app/services/sop.service';

@Component({
  selector: 'advice-form',
  templateUrl: './advice-form.component.html',
  styleUrls: ['./advice-form.component.scss']
})
export class AdviceFormComponent implements OnInit {

  currentStep: number = 0
  subscription: any

  project: Project = new Project()
  epe: Epe = new Epe()

  // alert variables

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  constructor(
    private router: Router,
    private service: AssociationsService,
    private helper: AssociationHelper,
    public auth: SessionStorage,
    private cache: SopStorage,
    private drupalService: SopService,
    private route: ActivatedRoute,
  ) { 

  }

  async ngOnInit(){
  
  }

  ngAfterViewInit(){
    this.subscription = this.router.events.subscribe(async (event) => {
      if(event instanceof NavigationEnd){
        if (event.url.includes('zero-step')){
          this.currentStep = 0
        }
        else if (event.url.includes('first-step')){
          this.currentStep = 1
        }
        else if (event.url.includes('second-step')){
          this.currentStep = 2
        }
        else if (event.url.includes('third-step')){
          this.currentStep = 3
        }
      }
    })
  }

  goToStep(n: number): void{
    switch(n){
      case 0:
        this.router.navigateByUrl('SOT/(advice-form:zero-step)')
        break;
      case 1:
        this.router.navigateByUrl('SOT/(advice-form:first-step)')
        break;
      case 2:
        this.router.navigateByUrl('SOT/(advice-form:second-step)')
        break;
      case 3:
        this.router.navigateByUrl('SOT/(advice-form:third-step)')
        break;
    }
  }

  getCircleState(circle: number): string{
    if(circle <= this.currentStep)
      return 'active'
    return ''
  }

  getProgressBar(): string{
    switch(this.currentStep){
      case 0:
        return '0%';
      case 1:
        return '30%';
      case 2:
        return '60%';
      case 3:
        return '90%';
      case 4:
        return '100%';
      default:
        return '0';
    }
  }

  addAlert(message: string, type: Alert){

    this.alertMessage = message
    this.alertType = type
    this.showAlert = true

  }
  

}
