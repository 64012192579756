import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse
} from '@angular/common/http';

import { catchError, EMPTY, Observable } from 'rxjs';
import { environment } from 'environments/environment';

/** Add header and pass request through to the next request handler. */
@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({'Access-Control-Allow-Origin' : '*'});
    headers.set('Content-Type', 'application/json; charset=utf-8');
    req = req.clone({
      setHeaders: 
      { 
        'Acess-Control-Allow-Origin': '*',
        'Content-Type': 'application/json; charset=utf-8'
      }
    });
    return next.handle(req)
  }
}

/** Process error request through to the next request handler without interrupt the Observable. */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(
        (err : HttpErrorResponse) => {
          if (err.error instanceof Error) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
          } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Response returned code ${err.status}, message: ${err.error.message}`);
          }
          // if (err.status === 401 || err.status === 403)
          //   window.location.href = environment.apiHost+"/user/login";
          return next.handle(req)
        }
      )
    );
  }
}

