import { Component, OnInit } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AssociationMongo } from 'app/models/association';
import { Epe } from 'app/models/epe';
import { Project } from 'app/models/project';
import { AssociationsService } from 'app/services/associations.service';
import { SopService } from 'app/services/sop.service';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { SessionStorage } from 'app/storage/session.storage';
import { SopStorage } from 'app/storage/sop.storage';
import { catchError, EMPTY, forkJoin, map, of } from 'rxjs';
import * as _ from 'lodash';
import { AssociationHelper } from 'app/storage/association.storage';
import { Router } from '@angular/router';


@Component({
  selector: 'surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['../sop.component.scss']
})
export class SurveysComponent implements OnInit {
  loading: boolean = true
  sortSelected = 'Project'
  sortDirection = 'ASC'
  headerRow = [ 
    'ID',
    'Project', 
    'EPE', 
    'Status', 
    'Project Survey 1',
    'Project Survey 2',
    'Project Survey 3',
    'Project Survey 4',
    'Final Epe Survey',
    'Final Project Survey'
  ]

  surveysHeaderRow = [
    'ID',
    'Project',
    'EPE',
    "Project meeting 1: How helpful was the call with the expert? 1 (poor) – 5 (excellent)",
    "Project meeting 1: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)",
    "Project meeting 1: Free comment box",

    "Project meeting 2: How helpful was the call with the expert? 1 (poor) – 5 (excellent)",
    "Project meeting 2: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)",
    "Project meeting 2: Free comment box",

    "Project meeting 3: How helpful was the call with the expert? 1 (poor) – 5 (excellent)",
    "Project meeting 3: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)",
    "Project meeting 3: Free comment box",

    "Project meeting 4: How helpful was the call with the expert? 1 (poor) – 5 (excellent)",
    "Project meeting 4: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)",
    "Project meeting 4: Free comment box",

    "EPE final: How would you rank the experience with platform? 1 (poor) – 5 (excellent)",
    "EPE final: How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)",
    "EPE final: Please add any further comments or suggestions in the box below",

    "Project final: How would you rank the experience with the expert? 1 (poor) – 5 (excellent)",
    "Project final: How would you rank HSbooster support? 1 (poor) – 5 (excellent)",
    "Project final: Comment on any activities you were not able to complete",
    "Project final: Do you think you will follow the expert suggestions for the future strategy?",
    "Project final: Please add any further comments or suggestions in the box below"
  ]


  epes: Epe[] | null = []
  associations: AssociationMongo[] | null = []
  projects: Project[] | null = []
  surveys: any[] | null = []  


  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS


  constructor(
    private sopService: SopService,
    private associationService: AssociationsService,
    private cache: SopStorage,
    private auth: SessionStorage,
    private helper: AssociationHelper,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.projects = this.cache.getProjects()
    this.epes = this.cache.getEpes()

    forkJoin({
      projects: !this.projects ? this.sopService.getAll('project') : of(null),
      epes: !this.epes ? this.sopService.getAll('epe') : of(null),
      associations: this.associationService.getAllServices()
    })
    .pipe(
      catchError(
        err => {
          this.addAlert("Association retriving error", Alert.ERROR)
          return EMPTY
        }
      )
    )
    .subscribe(
      then => {
        console.log(then)
        this.epes = then.epes ? then.epes : this.epes
        this.projects = then.projects ? then.projects : this.projects
        this.associations = then.associations?.map(
          association => (
            {
              ...association,
              'ID': association.project_id+"_"+association.epe_id,
              'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
              'EPE': this.epes?.find(epe => epe.id == association.epe_id)?.name + " " + this.epes?.find(epe => epe.id == association.epe_id)?.surname,
              'Status': association.association_status,
              'Project Survey 1': association.project_survey_form_1 ? "DONE" : "",
              'Project Survey 2': association.project_survey_form_2 ? "DONE" : "",
              'Project Survey 3': association.project_survey_form_3 ? "DONE" : "",
              'Project Survey 4': association.project_survey_form_4 ? "DONE" : "",
              'Final Epe Survey': association.epe_survey ? "DONE" : "",
              'Final Project Survey': association.project_survey ? "DONE" : "",
            }
          )
        )


        this.surveys = then.associations?.map(
          association => ({
            'ID': association.project_id+"_"+association.epe_id,
            'Project': this.projects?.find(project => project.id == association.project_id)?.project_acronym,
            'EPE': this.epes?.find(epe => epe.id == association.epe_id)?.name + " " + this.epes?.find(epe => epe.id == association.epe_id)?.surname,

            "Project meeting 1: How helpful was the call with the expert? 1 (poor) – 5 (excellent)": association.project_survey_form_1 && association.project_survey_form_1['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_1['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 1: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)": association.project_survey_form_1 && association.project_survey_form_1['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_1['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 1: Free comment box": association.project_survey_form_1 && association.project_survey_form_1['Free comment box'] ? association.project_survey_form_1['Free comment box'] : "",

            "Project meeting 2: How helpful was the call with the expert? 1 (poor) – 5 (excellent)": association.project_survey_form_2 && association.project_survey_form_2['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_2['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 2: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)": association.project_survey_form_2 && association.project_survey_form_2['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_2['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 2: Free comment box": association.project_survey_form_2 && association.project_survey_form_2['Free comment box'] ? association.project_survey_form_2['Free comment box'] : "",

            "Project meeting 3: How helpful was the call with the expert? 1 (poor) – 5 (excellent)": association.project_survey_form_3 && association.project_survey_form_3['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_3['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 3: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)": association.project_survey_form_3 && association.project_survey_form_3['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_3['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 3: Free comment box": association.project_survey_form_3 && association.project_survey_form_3['Free comment box'] ? association.project_survey_form_3['Free comment box'] : "",

            "Project meeting 4: How helpful was the call with the expert? 1 (poor) – 5 (excellent)": association.project_survey_form_4 && association.project_survey_form_4['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_4['How helpful was the call with the expert? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 4: Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)": association.project_survey_form_4 && association.project_survey_form_4['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] ? association.project_survey_form_4['Do you have a better understanding of how to approach your standardisation activities? 1 (poor) – 5 (excellent)'] : "",
            "Project meeting 4: Free comment box": association.project_survey_form_4 && association.project_survey_form_4['Free comment box'] ? association.project_survey_form_4['Free comment box'] : "",

            "EPE final: How would you rank the experience with platform? 1 (poor) – 5 (excellent)": association.epe_survey && association.epe_survey['How would you rank the experience with platform? 1 (poor) – 5 (excellent)'] ? association.epe_survey['How would you rank the experience with platform? 1 (poor) – 5 (excellent)'] : "",
            "EPE final: How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)": association.epe_survey && association.epe_survey['How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)'] ? association.epe_survey['How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)'] : "",
            "EPE final: Please add any further comments or suggestions in the box below": association.epe_survey && association.epe_survey['Please add any further comments or suggestions in the box below'] ? association.epe_survey['Please add any further comments or suggestions in the box below'] : "",

            "Project final: How would you rank the experience with the expert? 1 (poor) – 5 (excellent)": association.project_survey && association.project_survey['How would you rank the experience with the expert? 1 (poor) – 5 (excellent)'] ? association.project_survey['How would you rank the experience with the expert? 1 (poor) – 5 (excellent)'] : "",
            "Project final: How would you rank HSbooster support? 1 (poor) – 5 (excellent)": association.project_survey && association.project_survey['How would you rank HSbooster support? 1 (poor) – 5 (excellent)'] ? association.project_survey['How would you rank HSbooster support? 1 (poor) – 5 (excellent)'] : "",
            "Project final: Comment on any activities you were not able to complete": association.project_survey && association.project_survey['Comment on any activities you were not able to complete'] ? association.project_survey['Comment on any activities you were not able to complete'] : "",
            "Project final: Do you think you will follow the expert suggestions for the future strategy?": association.project_survey && association.project_survey['Do you think you will follow the expert suggestions for the future strategy?'] ? association.project_survey['Do you think you will follow the expert suggestions for the future strategy?'] : "",
            "Project final: Please add any further comments or suggestions in the box below": association.project_survey && association.project_survey['Please add any further comments or suggestions in the box below'] ? association.project_survey['Please add any further comments or suggestions in the box below'] : ""
          }
          )
        )

        this.cache.setEpes(this.epes!)
        this.cache.setProjects(this.projects!)

        this.associations?.sort(this.statusSort(this.sortDirection, this.sortSelected))
        this.loading = false
      }
    )
  }

  statusSort(sortDirection: string, sortSelected: string){
    return function(a: AssociationMongo, b: AssociationMongo) {
      if(sortDirection == 'ASC'){
        return a[sortSelected].localeCompare(b[sortSelected])
      }
      else{
        return a[sortSelected].localeCompare(b[sortSelected])*-1
      }
    }
  }

  sortCell(cell: string){
    if(this.sortDirection == 'ASC'){
      this.sortDirection = 'DESC'
    }
    else{
      this.sortDirection = 'ASC'
    }
    this.sortSelected = cell
    console.log(this.sortDirection)
    console.log(this.sortSelected)
    this.associations?.sort(this.statusSort(this.sortDirection, this.sortSelected))
    console.log(this.associations)
  }

  exportAuthorized(){
    return this.auth.isAdmin() || this.auth.isCallManager() || this.auth.isProjectBoard()
  }

  stripHtmlTags(content: string): string {
    const div = document.createElement('div');
    div.innerHTML = content;
    return div.textContent || div.innerText || '';
  }

  exportCSV(){
    var surveys = [...this.surveys!]
    var surveys_picked = _.map(surveys, ass => _.pick(ass, this.surveysHeaderRow))
    surveys_picked.forEach(survey => {
      for (const key in survey) {
        if (survey.hasOwnProperty(key)) {
          survey[key] = this.stripHtmlTags(survey[key]);
        }
      }
    });

    let csvOptions = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: true,
      title: "SOP Services",
      useBom: true,
      noDownload: false,
      headers: this.surveysHeaderRow
    };
    
    new AngularCsv(surveys_picked, 'SOP-Surveys-HSBooster', csvOptions);
  }

  openNewTab(url: any){
    window.open(url)
  }

  getMeetingComplete(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['complete']){
      return 'DONE'
    }

    return ''
  }

  getMeetingStatus(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['dateSetted']){
      if(meetingForm['meetingCompleted']){
        return 'DONE'
      }
      else{
        return 'SET'
      }
    }
    return ''
  }

  getMeetingDate(meetingForm: any){
    if(!meetingForm){
      return ''
    }
    if(meetingForm['dateSetted'] && meetingForm['date']){
      return meetingForm['date']
    }
    return ''
  }

  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

  isCurrentEpe(status: string | undefined): boolean{

    switch(status){
      case 'open':
      case 'waiting':
      case 'not_approved':
      case undefined:
        return false
    }
    return true

  }

  goToService(project: number | undefined, epe: number| undefined){

    if(!project || !epe)
      return
    
    let projectAssociations = this.associations?.filter( x => x.project_id == project)

    let epe_id = projectAssociations!.find(x => this.isCurrentEpe(x.association_status))?.epe_id
    
    if(epe_id)
      this.helper.setSelectedEpe(epe_id)
    else
      this.helper.setSelectedEpe(-1)

    let meeting = projectAssociations!.find( x => x.epe_id == epe_id && x.project_id == project)?.meeting

    if(meeting)
      this.helper.setMeetingForm(meeting)

    this.helper.setSelectedProject(project)

    let form = this.helper.getMeetingForm()
    if(this.auth.isProject() && form <= 1){
      this.addAlert("Please wait until the Epe complete at least one form", Alert.WARNING)
      return
    }
    if(form === 0 || !form)
      form = 1
    form = this.auth.isProject() && form < 7 ? form - 1 : form
    console.log(form)
    let route = this.auth.isProject() ? 'summary' : 'first'
    if(form < 6){
      console.log("There")
      this.router.navigateByUrl(`form/(meeting-form:meeting_${form}/(meeting_${form}:${route}))`)
    }
    else if(this.auth.isProjectBoard() || this.auth.isCallManager() || this.auth.isAdmin()){
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:admin))`)
    }
    else if(form == 6){
      this.router.navigateByUrl(`form/(meeting-form:survey-form)`)
    }
    else{
      console.log("here")
      this.router.navigateByUrl(`form/(meeting-form:survey-form/(survey-form:summary))`)
    }

  }

  goToDashboard(project: number | undefined, epe: number| undefined){
    if(!project || !epe)
      return
    
    let projectAssociations = this.associations?.filter( x => x.project_id == project)
    let epe_id = projectAssociations!.find(x => this.isCurrentEpe(x.association_status))?.epe_id

    if(epe_id)
      this.helper.setSelectedEpe(epe_id)
    else
      this.helper.setSelectedEpe(-1)

    this.helper.setSelectedProject(project)

    this.router.navigateByUrl('survey-dashboard')
  }

}
