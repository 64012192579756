import { SessionStorage } from 'app/storage/session.storage';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { lastValueFrom } from 'rxjs';
import { MeetingFormService } from '../../meeting-form.service';

@Component({
  selector: 'form-summary',
  templateUrl: './form-summary.html',
  styleUrls: ['../../meeting-form.component.scss']
})
export class FormSummary implements OnInit {

  objectKeys = Object.keys;
  @Input() data: {[index:string]:any} = {}
  summary: {[index:string]:any} = {}
  loading: boolean = true
  form: FormGroup
  @Input() report:boolean  = true

  constructor(
    private meetingService: MeetingFormService,
    private service: AssociationsService,
    private route: ActivatedRoute,
    private helper: AssociationHelper,
    private fb: FormBuilder,
    private auth: SessionStorage,
    private router: Router
  ) {
    this.form = this.fb.group({})
  }

  async ngOnInit() {
    var data = Object.keys(this.route.snapshot.data).length ? this.route.snapshot.data : this.data

    console.log(this.route.snapshot.data)
    console.log(this.data)
    console.log(data)
    switch(data['form']){
      case "report":
        this.summary = this.meetingService.getReportSubmit()
        break
      case "project_survey_advice":
        this.summary = this.meetingService.getProjectAdviceFormSubmit()
        break
      case "survey":
        if(this.auth.isProject()){
          var response = await lastValueFrom(this.service.getProjectSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
        }
        else if(this.auth.isEpe()){
          var response = await lastValueFrom(this.service.getEpeSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
        }
        else if(this.auth.isProjectBoard() || this.auth.isCallManager()){
          if(data['user'] == 'epe'){
            var response = await lastValueFrom(this.service.getEpeSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
            console.log(response)
          }
          else{
            var response = await lastValueFrom(this.service.getProjectSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
            console.log(response)
          }
          console.log("....")
          console.log(response)
        }
        else{
          response = null
        }

        if(!response){
          this.loading = false
          return
        }

        if(this.auth.isProject()){
          console.log("project summary")
          this.summary = response['project_survey']
          if(!this.summary){
            this.router.navigateByUrl(`form/(meeting-form:survey-form)`)
          }
        }
        else if(this.auth.isEpe()){
          console.log("epe summary")
          this.summary = response['epe_survey']
        }
        else if(this.auth.isProjectBoard() || this.auth.isCallManager()){
          console.log(data)
          console.log(data['user'])
          console.log(response['epe_survey'])
          console.log(response['epe_project'])
          this.summary = data['user'] == 'epe' ? response['epe_survey'] : response['project_survey']
        }
        console.log(this.summary)
        break
      default:
        this.summary = this.meetingService.getSubmit()
        console.log("SUMMARY FORM")
        console.log(this.summary)
        break
    }

    this.loading = false
  }

  getKeys(): any[] {
    return Object.keys(this.summary)
  }

}
