<div class="form-container">
    <div class="form-box">
        <div *ngFor="let question of questions">
            <open-question *ngIf="isOpenQuestion(question)" [type]="'survey'" [question]="getQuestionKey(question)" [editor]="getQuestion(question)"></open-question>
            <tick-box *ngIf="!isOpenQuestion(question)" [fields]="question" [type]="'survey'"></tick-box>
        </div>
        <button *ngIf="toSubmit" (click)="confirm()" class="u-btn sop-btn">Save</button>
    </div>
</div>



<simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert"></simple-alert>