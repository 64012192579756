import { Component, OnInit } from '@angular/core';
import { SessionStorage } from 'app/storage/session.storage';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    roles: string[];
}

/**
 * { path: '/dashboard', title: 'Dashboard',  icon: 'pe-7s-graph', class: '' },
    { path: '/projects', title: 'Projects',  icon:'pe-7s-rocket', class: '' },
    { path: '/associations', title: 'Associations',  icon:'pe-7s-network', class: '' },
    { path: '/pippo', title: '-----------',  icon:'pe-7s-network', class: '' },
 */
export const ROUTES: RouteInfo[] = [
    // { path: '/epes', title: 'EPEs',  icon:'pe-7s-users', class: '', roles: ['project_board', 'call_manager'] },
    // { path: '/projects', title: 'Projects',  icon:'pe-7s-rocket', class: '', roles: ['project_board', 'call_manager'] },
    // { path: '/associations', title: 'Associations',  icon:'pe-7s-network', class: '', roles: ['project_board', 'call_manager'] },
    // { path: '/assigned-services', title: 'Assigned Services',  icon:'pe-7s-network', class: '', roles: ['epe', 'project', 'epe_v2', 'project_v2'] },
    // { path: '/services-status', title: 'Services Status',  icon:'pe-7s-target', class: '', roles: ['project_board', 'call_manager'] },
    // { path: '/surveys-status', title: 'Surveys Status',  icon:'pe-7s-like2', class: '', roles: ['project_board', 'call_manager'] },
    { path: '/SOT', title: 'SOT',  icon:'pe-7s-network', class: '', roles: ['epe', 'project', 'epe_v2', 'project_v2', 'guest', 'project_board', 'call_manager'] },
    { path: '/statistics', title: 'Statistics',  icon:'pe-7s-graph2', class: '', roles: ['project_board', 'call_manager'] },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];

  constructor(public auth: SessionStorage) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
