import { Injectable } from '@angular/core';
import { Form, FormArray, FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})

export class FormService {

    statement : string = "";
    form ?: FormGroup;
    matrixAnswer: string[] = [];
    canNavigate: boolean = false;
    userRoles: string[] = [];

    question: Question = {
        "S" : "Standardisation knowledge (single choice)",
        "A" : "Aspects in research level (multiple choice)",
        "AS" : "Standardisation-related aspects in research level (multiple choice)",
        "B" : "Aspects in research level (multiple choice)",
        "BS" : "Standardisation-related aspects in research level (multiple choice)",
        "C" : "Aspects in research level (multiple choice)",
        "CS" : "Standardisation-related aspects in research level (multiple choice)",
    }

    options: Option = {
        "S" : [
            "I or my project participants have no or only little knowledge about standards and/or standardisation (knowledge that standards exist and/or minimal understanding of how standards are used).",
            "I or my project participants have intermediate knowledge about standards and standardisation (know and/or used standards in related or neighbouring areas and know how they are made and by whom, e.g. relevant standardisation committees like as TCs or WGs). ",
            "I or my project participants have advanced knowledge about standardisation (have used standards, know what standards exist and which are related to my field of research, know what standards are produced, know what type of standards deliverables exist), and are actively engaged in standardisation processes.",
        ],
        "A" : [
            "There is a clear understanding of terminology and definitions within my project’s technological field.",
            "My project identified some stakeholders relevant for the research.",
            "My project initiates laboratory studies and gathered relevant laboratory requirements.",
        ],
        "AS" : [
            "According to me or my project participants’ knowledge, there are no standards within the subject area of our project.",
            "Some parts of my project are related to existing standardisation areas, and/or there are European/international technological White Papers/TRs that address standards related to my field of research.",
            "I or my project participants consider our project to have initial potential for standardisation.",
        ],
        "B" : [
            "There is an apparent need for compliance with current/existing legislation and/or European legislation exists or is in preparation for the field of research in my project.",
            "Further market-oriented funding (e.g. ERC Proof of Concept) is established or being sought for my project.",
            "My project’s research/technology area is ready for validation/testing or contains testing and/or measurement methods demonstrating the use cases/processes to show the range/stability/validity of the technology.",
        ],
        "BS" : [
            "I or my project participants believe that our project has several potentials for standardisation.",
            "My product or technology is related to existing standards and/or ongoing standardisation activities.",
            "There is a clear interoperability issue with existing technological areas and thus a possible contradiction with existing standards.",
        ],
        "C" : [
            "There is a potential to introduce my project’s technology into future EU policies and strategies.",
            "My project’s research area is for the global market, i.e. interoperability and links to existing technologies are important to ease market access for our project’s products or technologies.",
            "My product or technology has the potential to be accepted on the market, need to comply with and depend on EU legislation and is subject to CE marking.",
        ],
        "CS" : [
            "My product or technology is related to (upcoming) standardisation requests (i.e. Mandates for harmonized standards) to support EU's legislation and policies.",
            "I have an idea for a standard, but I have difficulty to place this idea in the standardisation system (e.g. TC/WG are not interested).",
            "My project’s results are relevant for existing technology/service areas within well-defined market structures and has high standardisation potential (i.e. in new standards and/or revised existing standards)",
        ],
    }

    matrix: Matrix = {
        "S1" : [101, 2, 7, 11, 17, 23, 24],
        "S2" : [102, 2, 7, 11, 17, 23, 24],
        "S3" : [103, 2, 7, 11, 17, 23],
        "A1" : [6, 19],
        "A2" : [3, 12],
        "A3" : [2],
        "AS1" : [2],
        "AS2" : [5,7],
        "AS3" : [7, 13, 16],
        "B1" : [5, 14, 20],
        "B2" : [9, 14, 24],
        "B3" : [7, 11, 16, 17],
        "BS1" : [8, 13, 17],
        "BS2" : [10, 18],
        "BS3" : [8, 16, 18],
        "C1" : [4, 9, 20],
        "C2" : [14, 17, 20, 21],
        "C3" : [4, 10, 21],
        "CS1" : [14, 20, 23],
        "CS2" : [8, 15, 16, 18],
        "CS3" : [13, 17, 23],
    }

    actions : Action = {
        101: ['seek knowledge about standards and standardisation', 'Seek information about standards and standardisation - to increase your knowledge and to conduct the standardisation activities in your project (e.g. on the HSbooster.eu web page you can find training resources about many aspects of standards and standardisation processes, at the <a href="https://hsbooster.eu/training-academy/beginner" class="normal-link" target="_blank">Beginner Level</a>)'],
        102: ['improve knowledge about standards and standardisation', 'Seek information about standards and standardisation - to improve your knowledge and to conduct the standardisation activities in your project (e.g. on the HSbooster.eu web page you can find training resources about many aspects of standards and standardisation processes at the <a href="https://hsbooster.eu/training-academy/intermediate" class="normal-link" target="_blank">Intermediate level</a>)'],
        103: ['optimise knowledge about standards and standardisation', 'Seek information about standards and standardisation - to optimise your knowledge and to conduct the standardisation activities in your project (e.g. on the HSbooster.eu web page you can find training resources about many aspects of standards and standardisation processes,  at the <a href="https://hsbooster.eu/training-academy/advanced" class="normal-link" target="_blank">advanced level</a>)'],
        2: ['analyse existing standards and ongoing standardisation work', 'Perform an analysis of existing standards and ongoing standardisation activities relevant for your project - to access the state-of-the-art in the field that you are working in and to consider applying the standards in the project. This analysis can be performed through a national standards body, e.g. of the country of the project coordinator; or directly by somebody from the project using standards databases such as <a href="https://iso.org/obp/" class="normal-link" target="_blank">www.iso.org/obp/</a>'],
        3: ['map relevant standardisation committees', 'Perform a mapping of relevant standardisation committees (i.e. TCs, WGs) - to understand what standardisation activities are currently being conducted and which topics for new standardisation activities are in the standardisation body’s work programme (e.g. this can be performed through a national standards body, e.g. of the country of the project coordinator; or by searching on the SDO websites)'],
        4: ['align your technology with standards', 'Align your technology to existing standards - to support compliance'],
        5: ['investigate standardisation-related guidance documents', 'Investigate whether a Standards Developing Organisation has produced or works on the development of a guidance document (e.g. white paper, Technical Report or Roadmap) in the relevant research working area of your project and establish contact to the authors of this document - to gain insights on relevant standardisation topics and to exchange with stakeholders working on your research subject (e.g. use your NSB to create the necessary contact).'],
        6: ['summarise definitions of project terms', 'Summarise the main research terms and their possible definitions - to develop a project glossary or terminology (use definitions of terms in standards, available from e.g. www.iso.org/obp/ and/or through ISO ICS code thematic structure of standards or through the IEC terms and definition database Electropedia).'],
        7: ['do a standards gap analysis', 'Do a gap analysis of standards lacking within your subject or technology area by comparing end-user needs with envisaged project results and the identified existing standards - to identify areas in need of standardisation and where there is potential for starting standardisation processes. This can be performed through a national standards body, e.g. of the country of the project coordinator'],
        8: ['summarise need of change in standards', 'Summarise the urgent need for change in existing standards or for having this new standard - to provide benefits of your project result for standardisation and society'],
        9: ['contribute to standardisation-related guidance documents', 'Contribute to the development of a guidance document (e.g. white paper, Roadmap) - to suggest future standardisation work or aspects relevant for standardisation and to intensively engage with stakeholders of your research field (e.g. via your NSB or the author of this guidance document)'],
        10: ['describe the benefits of your technology for standardisation', 'Describe the benefits of your technology and how standardisation or a standard on this topic can support - to boost your technology'],
        11: ['decide on standardisation strategy', 'Decide on a standardisation strategy for the project and assess the various paths for transferring your research into standardisation (e.g., development of a new standardisation deliverable, such as CEN Workshop Agreements (CWA) or  New Work Item Proposal (NWIP) for European Norms (ENs), Technical Specifications (TS), Technical Reports (TR), or contributing to existing standards or ongoing standardisation efforts). This will help to identify the most suitable standardisation option to select for instance, by evaluating the identified standardisation potentials using specific assessment criteria'],
        12: ['engage standardisation experts in your project', 'Engage experts active in standardisation (such as Technical Committees [TCs], Working Groups [WGs]) to involve them in the project. This engagement is essential to fulfil the requirement of outreach and involvement of all relevant stakeholders. The European standardisation system, with over 200,000 experts from various types of organisations, offers an optimal platform for such engagement.'],
        13: ['establish a liaison with a standardisation committee', 'Establish a liaison for your project with a Technical Committee (TC) or Working Group (WG) within a national, European, or International Standards Developing Organisation. This will offer you insight and knowledge about the latest efforts in standard creation in specific fields, and allow you to directly influence the development of standards. You can achieve this by passively monitoring the work of a TC or WG or by actively sharing your views and expertise, without the obligation to vote or participate actively in the standard creation process (for reference, see ISO/IEC Directives Part 1 or CEN-CENELEC Guide 25'],
        14: ['become a member of a standardisation committee', 'Become a member of a relevant standardisation committee (i.e. TC, WG) and particpate actively - to get insights about ongoing standardisation work and to influence the content of new standards (contact to relevant committees could be established through your NSB)'],
        15: ['align standardisation activities with project events', 'Align project events or activities, such as public workshops or co-creation sessions, with standardisation activities. This alignment aims to garner additional support for standardisation by leveraging the flexible processes involved, for instance, those of a CEN Workshop Agreement (CWA)'],
        16: ['develop a Workshop Agreement', "Initiate and develop a Workshop Agreement, such as an International Workshop Agreement (IWA) or CEN Workshop Agreement (CWA). These agreements can be efficiently executed within the project's timeframe, allowing you to transfer your research results into standardisation and to foster engagement with relevant stakeholders. Consider integrating these agreements with co-creation sessions or project activities to enhance the standardisation work"],
        17: ['contribute to standardisation activities', "Propose (for example, a New Work Item Proposal [NWIP]) or contribute to the development of standards or other standardisation deliverables such as European Norms (ENs), Technical Specifications (TS), Technical Reports (TR), or CEN Workshop Agreements (CWA). This approach is to directly transfer the outcomes of your research project into standardisation, thereby making them more broadly available and exploitable. For instance, you could engage with Standards Development Organisations (SDOs) like CEN-CENELEC for CWAs, or with relevant Technical Committees (TCs) or Working Groups (WGs) for ENs, TS, TR; or provide commentary on ongoing standardisation work"],
        18: ['contribute to standards revision', "Contribute to the revision of existing standards with your project's findings to positively influence and align current standards with the results of your project"],
        19: ['contribute to terminology standards', "Contribute to standardisation activities pertaining to the definition of terms, especially if terminologies in your field do not exist or are inadequately defined from your perspective. For example, engage with the relevant Technical Committee (TC) or Working Group (WG) to propose modifications."],
        20: ['support responding to standardisation mandates', 'Support responding to standardisation requests from the European Commission, such as mandates, by integrating your project results into standardisation efforts. This will aid in the development of harmonised standards and ensure compliance with EU regulations, allowing for the benefits of Europe-wide validity and application. Achieve this by engaging stakeholders working on the standardisation requests.'],
        21: ["promote standards' use for your technology", "Promote the application of standards relevant to your technology to demonstrate compliance with the current state-of-the-art in your research field. This can be achieved, for instance, by referencing the pertinent standards within your technology's manual or documentation"],
        22: ['apply standardisation results in different scenarios', 'Apply the standardisation deliverables across various scenarios, such as local or international cases, to highlight the advantages of its utilisation and the challenges encountered in its absence'],
        23: ['promote standardisation results', 'Promote and capitalise on the results of standardisation, for example, by featuring them in conferences and articles. This increases their impact and supports wider dissemination and subsequent utilisation'],
        24: ['apply for HSBooster Premium service', 'Apply for the premium service via HSbooster.eu to receive detailed support for your standardisation activities from experts within your research field'],     
    }

    sActions : number[] = [];
        
    constructor() {}

    setStatement(statement: string){
        this.statement = statement;
        this.sActions = [];
    }

    getQuestionKeysByStatement() : string[] {
        if (this.statement === ""){
            return [];
        }

        let keys: string[] = [];
        keys.push("S");
        if (this.statement === 'A'){
            keys.push("A");
            keys.push("AS");
        }else if (this.statement === 'B'){
            keys.push("B");
            keys.push("BS");
        }else if (this.statement === 'C'){
            keys.push("C");
            keys.push("CS");
        }
        return keys;
    }

    getQuestionByKey(key: string) : string {
        return this.question[key];
    }

    getQuestionByStatement() : string[] {
        let keys = this.getQuestionKeysByStatement();
        let questions: string[] = [];
        for (let key of keys){
            questions.push(this.question[key]);
        }
        return questions;
    }

    getOptionsByQuestionKey(key: string) : string[] {
        return this.options[key];
    }

    setAnswers(answer: any){
        this.matrixAnswer = [];
        let keys = this.getQuestionKeysByStatement();
        for (let key of keys){
            if (answer[this.question[key]].length > 0){
                for (let option of answer[this.question[key]]){
                    this.matrixAnswer.push(key + (this.options[key].indexOf(option)+1));
                }
            }
        }
        console.log(this.matrixAnswer);
        this.getSActionsByMatrixAnswer();
        
    }

    getSActionsByMatrixAnswer() {
        for (let answer of this.matrixAnswer){
            console.log('answer ' + answer);
            let actions = this.matrix[answer];
            console.log('actions ' + actions);
                for (let action of actions){
                    if (!this.sActions.includes(action)){
                        this.sActions.push(action);
                    }
                }
        }
        this.sActions = this.sActions.filter(x => x > 100).concat(this.sActions.filter(x => x < 100).sort((a,b) => a-b));
        console.log(this.sActions);
    }

    getAnswersByMatrixAnswerOfQuestionKey(key: string) : string[] {
        let answers: string[] = [];
        this.getOptionsByQuestionKey(key).forEach((option, index) => {
            if (this.matrixAnswer.includes(key + (index+1))){
                answers.push(option);
            }
        }
        );
        return answers;
    }

    getSActionsIdByMatrixKeys(keys: string[] | undefined) : number[] {
        if (keys === undefined){
            return [];
        }
        let actions: number[] = [];
        for (let key of keys){
            let answer = this.matrix[key];
            for (let a of answer){
                if (!actions.includes(a)){
                    actions.push(a);
                }
            }
        }
        actions = actions.filter(x => x > 100).concat(actions.filter(x => x < 100).sort((a,b) => a-b));
        return actions;
    }

    getActionById(id: number) : string[] {
        return this.actions[id];
    }

    setFormValue(form: FormGroup){
        this.form = form;
    }
    
}

export interface Question {
    [key:string]: string
}

export interface Option {
    [key:string]: string[]
}

export interface Matrix {
    [key:string]: number[]
}

export interface Action {
    [key:number]: string[]
}