import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZeroStepComponent } from './zero-step/zero-step.component';
import { FirstStepComponent } from './first-step/first-step.component';
import { SecondStepComponent } from './second-step/second-step.component';
import { ThirdStepComponent } from './third-step/third-step.component';
import { AdviceFormRoutingModule } from './advice-form.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorFormComponent } from './ckeditor-form/ckeditor-form.component';
import { TickBoxComponent2 } from './ckeditor-form/components/tick-box.component';
import { SharedModule } from 'app/shared/shared.module';
import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
  declarations: [
    ZeroStepComponent,
    FirstStepComponent,
    SecondStepComponent,
    ThirdStepComponent,
    CKEditorFormComponent,
    TickBoxComponent2,
  ],
  imports: [
    CommonModule,
    AdviceFormRoutingModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    SharedModule,
    NgxCaptchaModule,
  ],
})
export class AdviceFormModule { }
