<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
    <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
      <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1 sop-header-layout">
        <div class="u-layout pb-2">
          <div class="u-layout-col">
            <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
              <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1 sop-header">
                <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Associations</h1>
              </div>
            </div>
            <div class="u-layout-row">
            <div class="u-container-style u-layout-cell u-layout-cell-2" [ngClass]="{'u-size-50': screenWidth>=1400 ,'u-size-60': screenWidth < 1400}">
              <div style="overflow-x: auto;">
                <div class="u-container-layout filter-layout">
                  <a (click)="changeFilter(AssociationStatusFilter.all)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn left" [ngClass]="{'active' : filter == AssociationStatusFilter.all}">All entries</a>
                  <a (click)="changeFilter(AssociationStatusFilter.open)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.open}">Open</a>
                  <a (click)="changeFilter(AssociationStatusFilter.identified)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.identified}">Identified</a>
                  <a (click)="changeFilter(AssociationStatusFilter.selected)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.selected}">Selected</a>
                  <a (click)="changeFilter(AssociationStatusFilter.approved)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.approved}">Approved</a>
                  <a (click)="changeFilter(AssociationStatusFilter.not_approved)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.not_approved}">Not Approved</a>
                  <a (click)="changeFilter(AssociationStatusFilter.completed)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn center" [ngClass]="{'active' : filter == AssociationStatusFilter.completed}">Completed</a>
                  <a (click)="changeFilter(AssociationStatusFilter.closed)"  class="u-border-1 u-border-palette-5-base u-btn u-btn-round u-button-style u-hover-palette-4-base u-palette-2-light-3 u-text-hover-white filter-btn right" [ngClass]="{'active' : filter == AssociationStatusFilter.closed}">Closed</a>
                </div>
              </div>
            </div>
            <div class="u-container-style u-layout-cell u-layout-cell-2" [ngClass]="{'u-size-10' : screenWidth >= 1400,'u-size-60': screenWidth < 1400}">
              <div class="u-container-layout filter-layout">
                <input placeholder="Search Project" [(ngModel)]="search" (ngModelChange)="searchProject($event)" class="u-input u-input-rectangle u-radius-6 u-border-1 search-bar" [ngStyle]="{'margin-left' : screenWidth < 1400 ? 0 : ''}" >
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="u-clearfix u-palette-1-base u-section-2" id="sec-5e41">
    <loader *ngIf="loading"></loader>
    <div class="card-container">  
      <div *ngFor="let project of getProjects()" class="u-clearfix u-sheet u-valign-middle u-sheet-1 card-layout">
        <div class="u-clearfix u-expanded-width u-layout-wrap">
          <div class="u-layout projects-card">
            <div class="u-layout-col">
              <div class="u-size-30">
                <div class="u-layout-col">
                  <div class="u-container-style u-layout-cell u-palette-1-base u-shape-round u-size-60 u-top-left-radius-20 u-top-right-radius-20">
                    <div class="u-border-2 u-border-palette-2-base u-container-layout u-container-layout-1">
                      <div class="u-text  u-text-1 d-flex">
                        <p class="u-file-icon u-icon header-profile-icon">
                          <img class="profile-icon" src="https://hsbooster.eu{{project.picture}}" onerror="this.src='/assets/img/1177568.png'" alt="">
                        </p>&nbsp;
                        <p style="font-weight: 700;">
                          {{project.project_acronym}} ~ 
                          <span style="font-weight: 500;"><span *ngIf="project.version == 'v2'">{{project.title}} </span>{{project.name}} {{project.surname}}</span>
                          <br>
                          <span style="font-size: 0.725rem; color: #a8bed3;">{{project.organisation_type}}</span>
                          <br>
                          <span style="font-size: 0.725rem; color: #a8bed3;">{{project.email}}</span>
                        </p>                    
                        <br>
                      </div>
                      <a (click)="expand(project.id)"
                        class="u-border-2 u-border-black u-border-hover-black u-btn u-btn-round u-button-style u-hover-palette-4-light-3 u-none u-radius-21 u-text-body-color u-text-hover-black action-btn u-clearfix">
                        {{getBtnText(project.id)}}
                        &nbsp;&nbsp;
                        <span class="u-file-icon u-icon"><img src="/assets/img/271226.png" alt=""></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selected == project.id" class="u-size-30">
                <div class="u-layout-row">
                  <div [ngClass]="{'u-size-41': screenWidth >= 1600, 'u-size-60': screenWidth < 1600}"
                       class="u-container-style u-custom-color-1 u-layout-cell u-layout-cell-2">
                    <div class="u-container-layout sop-card-container">
                      <project-sopcard [project]="project"></project-sopcard>
                    </div>
                  </div>
                  <div [ngClass]="{'u-size-19': screenWidth >= 1600, 'u-size-60': screenWidth < 1600}"
                       class="u-container-style u-layout-cell u-palette-2-light-3 u-layout-cell-3">
                    <div class="u-container-layout sop-card-container">
                      <association-sopcard-small [association_status]="getStatus(project.id)" [epes]="getProjectEpes(project.id)" [project_id]="project.id" ></association-sopcard-small>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div class="u-border-2 u-border-no-top u-border-palette-2-base u-bottom-left-radius-20 u-bottom-right-radius-20 u-container-style u-group u-palette-1-base u-shape-round u-group-1 projects-card">
          <div class="u-container-layout sop-card-footer">
            <img class="u-image u-image-default u-preserve-proportions sop-status-img" src="/assets/img/{{getStatus(project.id)}}.png" onerror="this.src='/assets/img/pending.png'" alt="" data-image-width="89" data-image-height="25">
            <p style="cursor: pointer;" (click)="expand(project.id)" class="u-text u-text-default">
              <span class="u-file-icon u-icon"><img src="/assets/icons/{{getExpandImg(project.id)}}.png" alt=""></span>&nbsp;
              {{getExpand(project.id)}}​&nbsp;
            </p>
            <p class="u-text u-text-default u-text-variant">
              <span class="u-text-palette-2-dark-1">User ID:&nbsp;</span>
              <span style="font-weight: 700;">#{{project.id}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="getProjects().length == 0 && (filter != AssociationStatusFilter.all || (search && search.length > 0))">
      <p class="u-text u-text-default no-filter-msg">
        No Association present. Please revise the filter
      </p>
    </div>
    <div *ngIf="getProjects().length == 0 && !(filter != AssociationStatusFilter.all || (search && search.length > 0)) && !loading">
      <p *ngIf="auth.isEpe() && !auth.isAdmin() && !auth.isProjectBoard() && !auth.isCallManager()" class="u-text u-text-default no-filter-msg">
        No Association present. Please wait until a project is assigned to you.
      </p>
      <p *ngIf="auth.isProject() && !auth.isAdmin() && !auth.isProjectBoard() && !auth.isCallManager()" class="u-text u-text-default no-filter-msg">
        No Association present. Please wait until an epe is assigned to you.
      </p>
    </div>
  </section>
  <simple-alert [message]="alertMessage" [alert]="alertType" [(show)]="showAlert" ></simple-alert>
