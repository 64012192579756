import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { SessionStorage } from 'app/storage/session.storage';
import { lastValueFrom } from 'rxjs';
import { MeetingFormService } from '../meeting-form.service';

@Component({
  selector: 'survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss', '../meeting-form.component.scss']
})
export class SurveyComponent implements OnInit {
  loading: boolean = true
  form: FormGroup

  constructor(
    private helper: AssociationHelper,
    private router: Router,
    private service: AssociationsService,
    private formService: MeetingFormService,
    private fb: FormBuilder,
    public auth: SessionStorage
  ) { 
    if(this.auth.isProject()){
      this.form = this.fb.group({
        "How would you rank the experience with the expert? 1 (poor) – 5 (excellent)": new FormArray([]),
        "How would you rank HSbooster support? 1 (poor) – 5 (excellent)": new FormArray([]),
        "Comment on any activities you were not able to complete": ['', Validators.required],
        "Do you think you will follow the expert suggestions for the future strategy?": ['', Validators.required],
        "Please add any further comments or suggestions in the box below": ['', Validators.required],
      })
      this.formService.setProjectSurveyForm(this.form)
    }
    else{
      this.form = this.fb.group({
        "How would you rank the experience with platform? 1 (poor) – 5 (excellent)": new FormArray([]),
        "How would you rank the interaction and communication with the project? 1 (poor) – 5 (excellent)": new FormArray([]),
        "Please add any further comments or suggestions in the box below": ['', Validators.required],
      })
      this.formService.setEpeSurveyForm(this.form)
    }
  }

  async ngOnInit() {
    if(this.helper.getSelectedProject() == -1 || this.helper.getSelectedEpe() == -1){
      this.router.navigateByUrl(`assigned-services`)
    }

    if(this.auth.isProject()){
      var response = await lastValueFrom(this.service.getProjectSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }
    else{
      var response = await lastValueFrom(this.service.getEpeSurveyForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    }
    console.log(response)
    if(!response){
      this.loading = false
      return
    }
    let form: { [key: string]: any; } = response['survey']
    
    if(form){
      if(this.auth.isProject()){
        this.formService.updateEpeSurveyForm(form)
      }
      else{
        this.formService.updateProjectSurveyForm(form)
      }
    }
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
    
  }


}