import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssociationsService } from 'app/services/associations.service';
import { AssociationHelper } from 'app/storage/association.storage';
import { catchError, EMPTY, fromEventPattern, lastValueFrom } from 'rxjs';
import { Alert } from 'app/shared/alerts/simple-message/simple-message.component';
import { MeetingFormService } from '../meeting-form.service';
import { SessionStorage } from 'app/storage/session.storage';
@Component({
  selector: 'report',
  templateUrl: './report.component.html',
  styleUrls: ['../meeting-form.component.scss']
})
export class ReportComponent implements OnInit {

  form_structure_name = "service_report"
  form: FormGroup
  loading: boolean = true

  questions: Question[];
  title: string; 

  showAlert: boolean = false
  alertMessage: string = ''
  alertType: Alert = Alert.SUCCESS

  toSubmit: boolean = true
  summary: {[index:string]:any} = {}
  read_only: boolean = false

  constructor(
    private fb: FormBuilder,
    private formService: MeetingFormService,
    private mongoService: AssociationsService,
    private helper: AssociationHelper,
    private router: Router,
    private meetingService: MeetingFormService,
    private auth: SessionStorage,
  ) { 
    //this.formService.clear() 
    this.form = this.fb.group({
      "1) Instructions to fill in this report": [''],
      "2) Description of first meetings": ['', Validators.required],
      //"3.1) Standardisation readiness": new FormArray([], [Validators.required]),
      "3.1) Standardisation readiness": new FormArray([]),
      "3.2) Standards and standardisation mapping landscape": new FormArray([]),
      "3.3) Access to standards": new FormArray([]),
      "3.4) Standardisation strategy and engagement": new FormArray([]),
      "3.5) Standards deliverables": new FormArray([]),
      "3.6) Training material": new FormArray([]),
      "3.6.1) Please specify any other training material suggested to the project in the box below": ['', Validators.required],
      "3.6.2) Do you recommend that the service recipient completes any of the following (tick what appropriate)?": new FormArray([]),
      "4) Final Recommendations": ['', Validators.required],
    })
    this.formService.setReportForm(this.form)

    try{
      let data = require(`assets/meeting-form/service_report.json`)
      this.questions = data['questions']
      this.title = data['title']
    } catch (e){
      console.log(e)
      this.questions = []
      this.title = ''
    }
  }

  async ngOnInit() {
    let response = await lastValueFrom(this.mongoService.getReportForm(this.helper.getSelectedProject(), this.helper.getSelectedEpe()), {defaultValue: null})
    console.log(response)
    if(!response){
      if(this.auth.isProject()){
        this.read_only = true
      }
      this.loading = false
      console.log("RETURN REPORT")
      return
    }
    let form: { [key: string]: any; } = response['report']
    this.formService.updateReportForm(form)
    this.form.valueChanges.subscribe(data => console.log('Form changes', data));

    this.loading = false
    
    if(this.router.url == "/form/(meeting-form:meeting_5/(meeting_5:summary))"){
      this.toSubmit = false
    }

    this.summary = this.meetingService.getReportSubmit()

    if(this.auth.isProject() || this.helper.getMeetingForm() > 5){
      this.read_only = true
    }
    else{
      this.read_only = false
    }
  }

  isOpenQuestion(question: Question) {
    return typeof question[this.getQuestionKey(question)] === 'string'
  }

  getQuestionKey(question: Question): string{
    return Object.keys(question)[0]
  }

  getQuestion(question: Question): string[] | string{
    return Object.values(question)[0]
  }

  getQuestionDescription(question: Question): string[] | string{
    return question["description"] ? question["description"] : ""
  }

  getKeys(): any[] {
    //console.log(this.summary)
    return Object.keys(this.summary)
  }



  confirm(): void{
    this.mongoService.submitReportForm(
      this.helper.getSelectedProject(), 
      this.helper.getSelectedEpe(), 
      this.formService.getReportSubmit()
      )
      .pipe(
        catchError(
          err => {
            this.addAlert("Activity Report update failed! Please contact the administrator", Alert.ERROR)
            return EMPTY
          }
        )
      )
      .subscribe(
        then => {
          console.log("SUBMIT REPORT_FORM")
          this.addAlert("Activity Report succesfully updated", Alert.SUCCESS)
        }
      )
  }


  addAlert(message: string, type: Alert){
    this.alertMessage = message
    this.alertType = type
    this.showAlert = true
  }

}

export interface Question {
  [key:string]: string[] | string
}
