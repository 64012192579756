import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SimpleMessageComponent } from './alerts/simple-message/simple-message.component';
import { OpenQuestionComponent } from 'app/form/meeting-form/form-steps/form-composer/components/open-question.component';
import { TickBoxComponent } from 'app/form/meeting-form/form-steps/form-composer/components/tick-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { ReportComponent } from 'app/form/meeting-form/report/report.component';
import { FormSummary } from 'app/form/meeting-form/form-steps/form-summary/form-summary';
import { LoaderComponent } from './loader/loader.component';
import { NgApexchartsModule } from "ng-apexcharts";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    NgApexchartsModule
  ],
  declarations: [
    SimpleMessageComponent,
    OpenQuestionComponent,
    TickBoxComponent,
    ReportComponent,
    LoaderComponent
  ],
  providers: [
  ],
  exports: [
    SimpleMessageComponent,
    OpenQuestionComponent,
    TickBoxComponent,
    ReportComponent,
    LoaderComponent
  ]
})
export class SharedModule { }
