<div class="form-container">
    <div class="form-box">
        <h3 *ngIf="summary && objectKeys(summary).length > 0  && !data['user']" style="text-align:center">Summary</h3>
        <h3 *ngIf="summary && objectKeys(summary).length > 0 && data['user'] == 'epe'" style="text-align:center">Epe's Survey Summary</h3>
        <h3 *ngIf="summary && objectKeys(summary).length > 0 && data['user'] == 'project'" style="text-align:center">Project's Survey Summary</h3>
        <h3 *ngIf="(!summary || objectKeys(summary).length == 0) && data['form'] == 'survey'" style="text-align:center">The {{data['user']}}'s survey is not ready yet</h3>
        <h3 *ngIf="(!summary || objectKeys(summary).length == 0) && data['form'] != 'survey'" style="text-align:center">The meeting has been skipped</h3>
        <div *ngIf="summary && objectKeys(summary).length > 0" class="summary-box">
            <div class="summary-row" *ngFor="let key of this.getKeys()">
                <p><strong>{{key}}</strong></p>
                <p>
                    <i *ngIf="summary[key]===true" class="fa fa-check"></i>
                    <span *ngIf="summary[key]!==true">
                        <div [innerHTML]="summary[key]"></div>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>


<report *ngIf="report"></report>

<svg class="inline-svg">
    <symbol id="check" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
    </symbol>
</svg>
<style>
    .inline-svg {
        position: absolute;
        width: 0;
        height: 0;
        pointer-events: none;
        user-select: none;
    }
</style>