<div class="form-container" style="margin-top: 20px;">
    <div class="form-box">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <h3 style="text-align:center">Introduction</h3>
        <loader *ngIf="loading"></loader>
        <div *ngIf="!loading" class="panel-group" id="accordion_0st">
            <div>
                <p>Welcome to the Standards Orientation Tool developed by HSbooster.eu, to support you design informed standardisation strategies. While this tool will offer specific recommendations based on the information you submit, we first want to equip you with some general advice to solidify your groundwork in standardisation. Please consider that if you’d like to be supported in some of the general activities listed below you can apply for the dedicated free consultancy service here: 
                    <a href="https://hsbooster.eu/hsboostereu-open-call" class="normal-link" target="_blank">https://hsbooster.eu/hsboostereu-open-call</a>
                </p>
            </div>
            
            <div *ngFor="let action of defaultAction; let i = index">
                <h6 style="margin-bottom: 0;">
                      {{ i+1}}. {{ getActionById(action)[0] }}
                </h6>
                <p  [innerHTML]="getActionById(action)[1]"></p>  
              </div>
              <div style="margin-top: 15px;">
                <p>
                    Now, we invite you to proceed with the questionnaire. Your responses will guide the tool in providing more tailored advice. Rest assured that your personal details will remain strictly confidential throughout this process. If you need any kind of support with the tool please contact us at 
                    <a class="normal-link">{{ 'info@hsbooster.eu' }}</a>
                </p>
              </div>
        </div>
        <div *ngIf="!loading" class="panel-group" id="accordion_1st">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h5 class="panel-title">
                        <a style="color:inherit">
                            Please provide accurate information to receive tailored advice. Your personal details will remain confidential.
                        </a>
                    </h5>
                </div>
                <div class="panel-collapse collapsed">
                <div class="panel-body">
                    <h4>Section 1: Personal information</h4>
                    <div class="form-group">
                        <div style="margin-bottom: 15px;">
                            <label for="title">Title:</label>
                            <select id="title" class="form-control" formControlName="title">
                                <option value="">-- Please Select --</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                                <option value="Ms">Ms</option>
                                <option value="Miss">Miss</option>
                            </select>
                            <div *ngIf="form.get('title')?.hasError('required') && form.get('title')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Title is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="name">First Name(s):</label>
                            <input id="name" class="form-control" type="text" placeholder="First Name(s)" formControlName="name">
                            <div *ngIf="form.get('name')?.hasError('required') && form.get('name')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px"> 
                                Name is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="surname">Surname:</label>
                            <input id="surname" class="form-control" type="text" placeholder="Surname" formControlName="surname">
                            <div *ngIf="form.get('surname')?.hasError('required') && form.get('surname')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Surname is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="phone">Phone Number:</label>
                            <input id="phone" class="form-control" type="text" placeholder="Phone Number" formControlName="phone">
                            <div *ngIf="form.get('phone')?.hasError('required') && form.get('phone')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Phone is required
                            </div>
                            <div *ngIf="form.get('phone')?.hasError('pattern') && form.get('phone')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Phone is invalid
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="organisation">Organisation:</label>
                            <input id="organisation" class="form-control" type="text" placeholder="Organisation" formControlName="organisation">
                            <div *ngIf="form.get('organisation')?.hasError('required') && form.get('organisation')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Organisation is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="organisation_type">Organisation Type:</label>
                            <select id="organisation_type" class="form-control" formControlName="organisation_type">
                                <option value="">-- Please Select --</option>
                                <option value="Academia/Research">Academia/Research</option>
                                <option value="Government/Public Service">Government/Public Service</option>
                                <option value="IT Consultancy/Development">IT Consultancy/Development</option>
                                <option value="Large Enterprise">Large Enterprise</option>
                                <option value="SDO/ National Standards Body / National Committee">SDO/ National Standards Body / National Committee</option>
                                <option value="Press & Media">Press & Media</option>
                                <option value="Small and Medium Enterprise">Small and Medium Enterprise</option>
                                <option value="Other">Other</option>
                            </select>
                            <div *ngIf="form.get('organisation_type')?.hasError('required') && form.get('organisation_type')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Organisation Type is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="gender">Gender:</label>
                            <select id="gender" class="form-control" formControlName="gender">
                                <option value="">-- Please Select --</option>
                                <option value="Female">Female</option>
                                <option value="Male">Male</option>
                                <option value="Prefer to self-describe">Prefer to self-describe</option>
                            </select>
                            <div *ngIf="form.get('gender')?.hasError('required') && form.get('gender')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Gender is required  
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="country">Country:</label>
                            <select id="country" class="form-control" formControlName="country">
                                <option value="">-- Please Select --</option>
                                <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>
                            </select>
                            <div *ngIf="form.get('country')?.hasError('required') && form.get('country')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Country is required
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div *ngIf="!loading" class="panel-group" id="accordion_1st">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h5 class="panel-title">
                        <a style="color:inherit">Please complete the following section if you are  participating in a research project funded by the European Union.</a>
                    </h5>
                </div>
            <div class="panel-collapse collapsed">
                <div class="panel-body">
                    <h4>Section 2: Project Information</h4>
                    <div class="form-group">
                        <div style="margin-bottom: 15px;">
                            <label for="grants_agreement_number">Grants Agreement Number:</label>
                            <input id="grants_agreement_number" class="form-control" type="text" placeholder="Grants Agreement Number" formControlName="grants_agreement_number">
                            <div *ngIf="form.get('grants_agreement_number')?.hasError('required') && form.get('grants_agreement_number')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Grants Agreement Number is required
                            </div>
                            <div *ngIf="form.get('grants_agreement_number')?.hasError('pattern') && form.get('grants_agreement_number')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Grants Agreement Number is invalid
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="project_acronym">Project Acronym:</label>
                            <input id="project_acronym" class="form-control" type="text" placeholder="Project Acronym" formControlName="project_acronym">
                            <div *ngIf="form.get('project_acronym')?.hasError('required') && form.get('project_acronym')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Project Acronym is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="full_project_name">Full Project Name:</label>
                            <input id="full_project_name" class="form-control" type="text" placeholder="Full Project Name" formControlName="full_project_name">
                            <div *ngIf="form.get('full_project_name')?.hasError('required') && form.get('full_project_name')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Full Project Name is required
                            </div>
                        </div>
                        <div style="margin-bottom: 15px;">
                            <label for="project_website">Project Website:</label>
                            <input id="project_website" class="form-control" type="text" placeholder="Project Website" formControlName="project_website">
                            <div *ngIf="form.get('project_website')?.hasError('required') && form.get('project_website')?.touched" class="alert alert-danger" style="border-radius: 4px; margin-top: 6px">
                                Project Website is required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <button *ngIf="!loading" type="submit" class="u-btn sop-btn">Continue</button>
        </form>

    </div>
</div>