<div class="form-container" style="margin-top: 20px;">
  <script src="https://www.google.com/recaptcha/api.js?render={{SITE_KEY}}"></script>
    <div class="form-box template-email">
      <h3 style="text-align:center">Advice report</h3>
      <h5 style="text-align:center">Based on your responses, here are some recommendations to enhance your project's standardisation readiness. These recommendations are tailored specifically to your project's stage </h5>
      
      <div *ngFor="let action of sActions; let i = index" class="panel-group" id="accordion_{{ action }}st">
        <div class="panel panel-default">
          <div class="panel-heading" data-toggle="collapse" [attr.data-target]="'#collapse_' + action" (click)="expand(action)" 
            style="display: flex; align-items: baseline; cursor: pointer;">
            <h6 class="panel-title">
              Advice {{ i+1 }}:
            </h6>
            <p class="panel-title" data-toggle="collapse" [attr.data-target]="'#collapse_' + action" style="margin-left: 4px;">{{ getActionById(action)[0] }}</p>
          </div>
          <div [id]="'collapse_' + action" class="panel-collapse collapse">
            <div class="panel-body">
              <div>
                <p  [innerHTML]="getActionById(action)[1]"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <div class="panel-group" id="defaulActions">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h5 class="panel-title">
              <a data-toggle="collapse" data-parent="#accordion" href="#collapse_default">General Advice</a>
            </h5>
          </div>

          <div id="collapse_default" class="panel-collapse collapse">
            <div class="panel-body">
              <div *ngFor="let action of defaultAction; let i = index" class="panel-group" id="accordion_{{ action }}st">
                <div class="panel panel-default">
                  <div class="panel-heading" data-toggle="collapse" [attr.data-target]="'#collapse_' + action" (click)="expand(action)" 
                    style="display: flex; align-items: baseline; cursor: pointer;">
                    <h6 class="panel-title">
                      Advice {{ i+1+sActions.length }}:
                    </h6>
                    <p class="panel-title" data-toggle="collapse" [attr.data-target]="'#collapse_' + action" style="margin-left: 4px;">{{ getActionById(action)[0] }}</p>
                  </div>
                  <div [id]="'collapse_' + action" class="panel-collapse collapse">
                    <div class="panel-body">
                      <p  [innerHTML]="getActionById(action)[1]"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div> -->
      <div>
        <h4>Conclusion:</h4>
        <p style="font-size: 16.5px;">
          Thank you for completing the Standardsation Readiness Tool survey. If you have any questions or require further assistance, please contact us at
           <a class="normal-link">{{ 'info@hbooster.eu' }}</a>.
        </p>
      </div>
      <button (click)="export()" class="u-btn sop-btn">Export</button>
    </div>
</div>

