<div class="form-container" style="margin-top: 20px;">
  <div class="form-box template-email">
    <h3 style="text-align:center">Section 3: Technology Readiness level</h3>

    <div class="panel-group" id="accordion_1st">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h5 class="panel-title">
            <a data-toggle="collapse" data-parent="#accordion_1st" >To better tailor our advice to your project's specific stage, please indicate the stage of your research project by identifying its Technology Readiness Level (TRL).</a>
          </h5>
        </div>
        <div id="collapsedString" class="panel-collapse collapsed">
          <div class="panel-body">
            <div class="form-group">
              <label for="selection"><h4>Select the stage of your research project:</h4></label>
              <select #selection id="selection" class="form-control">
                <option value="A">My research project is within the area of basic research (TRL 1-3).</option>
                <option value="B">My research project is/will be within the area of applied research (TRL 4-5).</option>
                <option value="C">My research project is/will be within the area of technology development and implementation (TRL 6-9).</option>
              </select>
            </div>
            <button class="u-btn sop-btn" (click)="submitForm()">Continue</button>
            <div style="margin-top: 50px;">
              <h5>TRL: Categories of Scale and Definitions</h5>
              <div class="panel-group" id="trlAccordion">
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h5 class="panel-title">
                      <a data-toggle="collapse" data-parent="#trlAccordion" href="#trlACollapse">A. Basic Research (TRL 1-3)</a>
                    </h5>
                  </div>
                  <div id="trlACollapse" class="panel-collapse collapse">
                    <div class="panel-body">
                      <h6>TRL 1 - basic principles observed </h6>
                      <p>This is the initial level of technology readiness. Scientific research starts its transition into applied research and development. Examples may include paper studies on a technology's foundational properties.</p>
                      <h6>TRL 2 - technology concept formulated </h6>
                      <p>The process of invention begins. After basic principles have been observed, potential practical applications are conceptualised. These applications are theoretical, often without concrete evidence or in-depth analysis for validation. Typically, examples at this stage focus on analytical studies.</p>
                      <h6>TRL 3 - experimental proof of concept </h6>
                      <p>Active research and development commence. This stage encompasses both analytical and laboratory studies, aiming to validate the theoretical predictions of distinct elements of the technology. For instance, it might involve testing components that haven't been fully integrated or aren't yet representative.</p>
                    </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h5 class="panel-title">
                      <a data-toggle="collapse" data-parent="#trlAccordion" href="#trlBCollapse">B. Applied Research (TRL 4-5)</a>
                    </h5>
                  </div>
                  <div id="trlBCollapse" class="panel-collapse collapse">
                    <div class="panel-body">
                      <h6>TRL 4 - technology validated in lab </h6>
                      <p>Basic technological components are integrated to establish that they will work together. This is relatively "low fidelity" compared to the eventual system. Examples include the integration of "ad hoc" hardware in the laboratory.</p>
                      <h6>TRL 5 - technology validated in relevant environment </h6>
                      <p>Basic technological components are integrated with reasonably realistic supporting elements so it can be tested in a simulated environment. Examples include "high fidelity" laboratory integration of components.</p>
                    </div>
                  </div>
                </div>
                <div class="panel panel-default">
                  <div class="panel-heading">
                    <h5 class="panel-title">
                      <a data-toggle="collapse" data-parent="#trlAccordion" href="#trlCCollapse">C. Development & Implementation (TRL 6-9)</a>
                    </h5>
                  </div>
                  <div id="trlCCollapse" class="panel-collapse collapse">
                    <div class="panel-body">
                      <h6>TRL 6 - technology demonstrated in relevant environment </h6>
                      <p>Representative model or prototype system, which is well beyond that of TRL 5, is tested in a relevant environment. Represents a major step up in a technology’s demonstrated readiness. Examples include testing a prototype in a high-fidelity laboratory environment or in simulated operational environment.</p>
                      <h6>TRL 7 - system prototype demonstration in operational environment </h6>
                      <p>Prototype near or at planned operational system. Represents a major step up from TRL 6, requiring the demonstration of an actual system prototype in an operational environment, such as in an aircraft, vehicle or space. Examples include testing the prototype in a test bed aircraft.</p>
                      <h6>TRL 8 - system complete and qualified </h6>
                      <p>Technology has been proven to work in its final form and under expected conditions. In almost all cases, this TRL represents the end of true system development. Examples include developmental test and evaluation of the system in its intended weapon system to determine if it meets design specifications.</p>
                      <h6>TRL 9 - actual system proven through successful mission operations </h6>
                      <p>Actual application of the technology in its final form and under mission conditions, such as those encountered in operational test and evaluation.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
