import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ZeroStepComponent } from './zero-step/zero-step.component';
import { FirstStepComponent } from './first-step/first-step.component';
import { SecondStepComponent } from './second-step/second-step.component';
import { ThirdStepComponent } from './third-step/third-step.component';

const routes = [

    {
        path: '',
        redirectTo: 'zero-step',
        pathMatch: 'full'
    },

    {
        path: 'zero-step',
        component: ZeroStepComponent,
    },

    {
        path: 'first-step',
        component: FirstStepComponent,
    },

    {
        path: 'second-step',
        component: SecondStepComponent,
    },

    {
        path: 'third-step',
        component: ThirdStepComponent,
    },

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdviceFormRoutingModule { }
