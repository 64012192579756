import { Model, MongoModel } from "./db.model";
import { ProjectDocument } from "./project_document";
import { StandardReference } from "./standard_reference";

export class Project implements Model {
    [index: string] :any
    username?:string
    email?:string
    sop_status?:string
    name?:string
    surname?:string
    picture?:string
    phone_number?:string
    organisation?:string
    organisation_type?:string
    gender?:string
    country?:string
    topics?:Object[] = []
    grant_agreement?:string
    project_acronym?:string
    full_project_name?:string
    funding_programme?:string
    other_funding_programme?:string
    project_call_topic_id?:string
    project_website?:string
    project_officer_name?:string
    information_to_attain?:string[] = []
    project_short_description?:string
    objectives_standardisation?:string
    why_the_project_applying?:string
    which_group_contribute_to?:string
    related_doc_application?:ProjectDocument[] = []
    version?:string

    //V2
    website_publication?:boolean
    standard_knowledge?:boolean
    standard_reference?:StandardReference[] = []
    title?:string

    /* PROJECT VERSION 1 */
    /*
          'id'
          'username'
          'email'
          'sop_status'
          'name'
          'surname'
          'picture'
          'phone_number'
          'organisation'
          'organisation_type'
          'gender'
          'country'
          'topics'
          'grant_agreement'
          'project_acronym'
          'full_project_name'
          'funding_programme'
          'project_call_topic_id'
          'project_website'
          'project_officer_name'
          'information_to_attain'
          'project_short_description'
          'objectives_standardisation'
          'why_the_project_applying'
          'which_group_contribute_to'
          'related_doc_application'
          'version'
    */

    /* PROJECT VERSION 2 */
        /*
          'id'
          'username'
          'email'
          'sop_status'
          'name'
          'surname'
          'picture'
          'phone_number'
          'organisation'
          'organisation_type'
          'gender'
          'country'
          'topics'
          'grant_agreement'
          'project_acronym'
          'full_project_name'
          'funding_programme'
          'project_call_topic_id'
          'project_website'
          'project_officer_name'
          'information_to_attain'
          'project_short_description'
          'objectives_standardisation'
          'why_the_project_applying'
          'standard_knowledge'
          'related_doc_application'
          'website_publication'
          'standard_reference'
          'title'
          'version'
        */


}

export class ProjectMongo extends MongoModel {
    [index: string] :any
    epe_id?: number
    sop_status?: string

    constructor(id: number, sop_status: string){
        
        super()
        this.epe_id = id
        this.sop_status = sop_status
        
    }

}