import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ckeditor-form',
  templateUrl: './ckeditor-form.component.html',
  styleUrls: ['./ckeditor-form.component.scss']
})
export class CKEditorFormComponent {
  ckeditorConfig = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'undo',
      'redo'
    ]
  };

  questions: string[] = [
    'Standardisation knowledge (single choice)',
    'Aspects in research level (multiple choice)',
    'Standardisation-related aspects in research level (multi-choice)',
  ];
  
  options: { [key: string]: string[] } = {
    'Standardisation knowledge (one choice)': [
      'S1: 	I or my project participants have no or only little knowledge about standards and/or standardisation (knowledge that standards exist and/or minimal understanding of how standards are used).',
      'S2: 	I or my project participants have intermediate knowledge about standards and standardisation (know and/or used standards in related or neighbouring areas and know how they are made and by whom, e.g. relevant standardisation committees like as TCs or WGs). ',
      'S3:    I or my project participants have advanced knowledge about standardisation (have used standards, know what standards exist and which are related to my field of research, know what standards are produced, know what type of standards deliverables exist), and are actively engaged in standardisation processes.',
    ],
    'Aspects in research level (multiple choice)': [
      'A1: 	There is a clear understanding of terminology and definitions within my project’s technological field.',
      'A2: 	My project identified some stakeholders relevant for the research.',
      'A3: 	My project initiates laboratory studies and gathered relevant laboratory requirements.',
    ],
    'Standardisation-related aspects in research level (multiple choice)': [
      'AS1: 	According to me or my project participants’ knowledge, there are no standards within the subject area of our project.',
      'AS2: 	Some parts of my project are related to existing standardisation areas, and/or there are European/international technological White Papers/TRs that address standards related to my field of research.',
      'AS3: 	I or my project participants consider our project to have initial potential for standardisation.',
    ],
  };

  constructor() {
  }

}


 