import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormService } from '../form.service';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import { SubmissionsService } from 'app/services/submissions.service';
import { ReCaptchaV3Service } from "ngx-captcha";
import { Observable } from 'rxjs';

@Component({
  selector: 'third-step',
  templateUrl: './third-step.component.html',
  styleUrls: ['../advice-form.component.scss', './third-step.component.scss'] 
})
export class ThirdStepComponent implements OnInit {
  readonly SITE_KEY = '6LdnMQopAAAAAJn25SJ19JPz4afrWy0hsO7E3yX2';
  action = 'register';
  token: string = '';

  sActions: number[] = [];
  defaultAction: number[] = [2, 7, 11, 17, 23];
  expandedAction: { [key: number]: boolean } = {};

  // pdf
  pdf = new jsPDF();
  x = 10;
  y = 60;
  pageHeight = this.pdf.internal.pageSize.getHeight();

  constructor(
    private formService: FormService,
    private router: Router,
    private submissionService: SubmissionsService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    // console.log("third step init");
    this.sActions = this.formService.sActions;
    this.sActions = this.sActions.filter((action) => { return !this.defaultAction.includes(action) });
    // console.log(this.formService.form, 'user form');
    // console.log(this.formService.statement, 'user statement');
    // console.log(this.formService.matrixAnswer, 'user matrix answer');
    
    
    this.generateToken().subscribe((token) => {
      this.postSubmission();
    }
    );
    
    if (this.sActions.length == 0){
      console.log("no data");
      this.router.navigateByUrl('SOT/(advice-form:first-step)');
    }
    this.sActions.forEach((action) => {
      this.expandedAction[action] = true;
    });
  }

  postSubmission() {
    console.log("post submission");
    let submission: any = {};
    submission['title'] = this.formService.form?.value.title;
    submission['name'] = this.formService.form?.value.name;
    submission['surname'] = this.formService.form?.value.surname;
    submission['phone'] = this.formService.form?.value.phone;
    submission['organisation'] = this.formService.form?.value.organisation;
    submission['organisation_type'] = this.formService.form?.value.organisation_type;
    submission['gender'] = this.formService.form?.value.gender;
    submission['country'] = this.formService.form?.value.country;
    submission['grants_agreement_number'] = this.formService.form?.value.grants_agreement_number;
    submission['project_acronym'] = this.formService.form?.value.project_acronym;
    submission['full_project_name'] = this.formService.form?.value.full_project_name;
    submission['project_website'] = this.formService.form?.value.project_website;
    submission['statement'] = this.formService.statement;
    submission['answers'] = this.formService.matrixAnswer;
    submission['userRoles'] = this.formService.userRoles;
    submission['token'] = this.token;
    // console.log(submission, 'submission');
    this.submissionService.createSubmission(submission).subscribe((res) => {
      // console.log(res, 'response');
    });
  }

  generateToken(){
    return new Observable((observer) => {
      this.reCaptchaV3Service.execute(this.SITE_KEY, this.action, (token) => {
        this.token = token;
        observer.next(token);
        observer.complete();
      });
    }
    );
  }

  getActionById(id: number){
    return this.formService.getActionById(id);
  }

  expand(action: number): void {
    this.expandedAction[action] = !this.expandedAction[action];
  }

  isExpanded(action: number): boolean {
    return this.expandedAction[action];
  }

  stripHtmlTags(html: string): string {
    return html.replace(/<a\b[^>]*>(.*?)<\/a>/g, '$1');
  }

  addPageIfNecessary(): void {
    if (this.y >= this.pageHeight - 10) {
      this.pdf.addPage();
      this.y = 20;
    }
  }

  addText(text: string): void {
    const textBoxWidth = 205;
    let text_temp = this.stripHtmlTags(text);
    const lines = this.pdf.splitTextToSize(text_temp, textBoxWidth-this.x);
    for (const line of lines) {
      this.addPageIfNecessary();
      this.pdf.setFont('helvetica', 'normal');
      this.pdf.text(line, this.x, this.y);
      this.y += 7;
    }
  }

  export() {
    var img = new Image();
    img.src = 'assets/img/logo.png';
    this.pdf.addImage(img, 'PNG', 10, 10, 60, 20);
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.setFontSize(20);
    this.pdf.text("Advice Report", 10, 45);
    this.pdf.setFontSize(10);
  

    if (this.formService.form?.value.grants_agreement_number || this.formService.form?.value.project_acronym || 
      this.formService.form?.value.full_project_name || this.formService.form?.value.project_website) {
      this.pdf.setFont('helvetica', 'bold');
      this.pdf.setFontSize(12);
      this.pdf.text("Project Information", this.x, this.y);
      this.pdf.setFontSize(10);
      this.y += 7;

      if (this.formService.form?.value.grants_agreement_number) {
        this.pdf.setFont('helvetica', 'bold');
        this.pdf.text("Grant Agreement Number:", this.x, this.y);
        this.x += 50;
        this.addText(this.formService.form?.value.grants_agreement_number);
        this.x -= 50;
      }
      if (this.formService.form?.value.project_acronym) {
        this.pdf.setFont('helvetica', 'bold');
        this.pdf.text("Project Acronym:", this.x, this.y);
        this.x += 50;
        this.addText(this.formService.form?.value.project_acronym);
        this.x -= 50;
      }
      if (this.formService.form?.value.full_project_name) {
        this.pdf.setFont('helvetica', 'bold');
        this.pdf.text("Full Project Name:", this.x, this.y);
        this.x += 50;
        this.addText(this.formService.form?.value.full_project_name);
        this.x -= 50;
      }
      if (this.formService.form?.value.project_website) {
        this.pdf.setFont('helvetica', 'bold');
        this.pdf.text("Project Website:", this.x, this.y);
        this.x += 50;
        this.addText(this.formService.form?.value.project_website);
        this.x -= 50;
      }
    }

    this.pdf.setFont('helvetica', 'bold');
    this.pdf.text("TRL of the project:", this.x, this.y);
    this.x += 50;
    switch (this.formService.statement) {
      case "A":
        this.addText("TRL 1-3 (Basic Research)");
        break;
      case "B":
        this.addText("TRL 4-5 (Applied Research)");
        break;
      case "C":
        this.addText("TRL 6-7 (Demonstration & Implementation)");
        break;
    }
    this.x -= 50;
    
    this.formService.getQuestionKeysByStatement().forEach(key => {
      if (this.formService.getAnswersByMatrixAnswerOfQuestionKey(key).length > 0) {
        this.addPageIfNecessary();
        this.pdf.setFont('helvetica', 'bold');
        this.pdf.text(this.formService.getQuestionByKey(key), this.x, this.y);
        this.y += 7;
        for (let i = 0; i < this.formService.getAnswersByMatrixAnswerOfQuestionKey(key).length; i++) {
          this.addText(i+1+") "+this.formService.getAnswersByMatrixAnswerOfQuestionKey(key)[i]);
        }
      }
    });
    this.y += 13;  
    this.addPageIfNecessary();
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.setFontSize(12);
    this.pdf.text("Specific Advice", this.x, this.y);
    this.pdf.setFontSize(10);
    this.y += 7;  
  
    for (let i = 0; i < this.sActions.length; i++) {
      this.addPageIfNecessary();
      this.pdf.setFont('helvetica', 'bold');
      this.pdf.text(`Advice ${i+1}: ${this.getActionById(this.sActions[i])[0]}`, this.x, this.y)
      this.y += 7;
      this.addText(this.getActionById(this.sActions[i])[1]);
      this.y += 3;
    }

    this.addPageIfNecessary();
    this.pdf.setFont('helvetica', 'bold');
    this.pdf.setFontSize(12);
    this.pdf.text("General Advice", this.x, this.y);
    this.pdf.setFontSize(10);
    this.y += 7;

    for (let i = 0; i < this.defaultAction.length; i++) {
      this.addPageIfNecessary();
      this.pdf.setFont('helvetica', 'bold');
      this.pdf.text(`Advice ${i+1+this.sActions.length}: ${this.getActionById(this.defaultAction[i])[0]}`,this.x, this.y)
      this.y += 7;
      this.addText(this.getActionById(this.defaultAction[i])[1]);
      this.y += 3;
    }

    this.pdf.save(`advice_report.pdf`);
  }

}
