import { ServicesComponent } from './sop/services/services.component';
import { AssignedServiceComponent } from './sop/assigned-service/assigned-service.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { EpesComponent } from './sop/epes/epes.component';
import { ProjectsComponent } from './sop/projects/projects.component';
import { AssociationComponent } from './sop/association/association.component';
import { AuthGuard } from './auth/auth.guard';
import { MeetingFormComponent } from './form/meeting-form/meeting-form.component';
import { EpeComponent } from './sop/epe/epe.component';
import { ProjectComponent } from './sop/project/project.component';
import { SurveysComponent } from './sop/surveys/surveys.component';
import { SurveyDashboardComponent } from "./survey-dashboard/survey-dashboard.component";
import { AdviceFormComponent } from './advice-form/advice-form.component';
import { StatisticsComponent } from './strl/statistics/statistics.component';

const routes: Routes =[

{
    path: '',
    component: AdminLayoutComponent,
},
{ 
    path: 'project/:id',
    component: ProjectComponent,
    canActivate: [AuthGuard]
},
{ 
    path: 'epe/:id',
    component: EpeComponent,
    canActivate: [AuthGuard]
},
{ 
    path: 'epes',
    component: EpesComponent,
    canActivate: [AuthGuard]
},
{ 
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard]
},
{ 
    path: 'associations',
    component: AssociationComponent,
    canActivate: [AuthGuard]
},
{ 
    path: 'assigned-services',
    component: AssignedServiceComponent,
    canActivate: [AuthGuard]
},
{ 
  path: 'services-status',
  component: ServicesComponent,
  canActivate: [AuthGuard]
},
{ 
  path: 'surveys-status',
  component: SurveysComponent,
  canActivate: [AuthGuard]
},
{ 
  path: 'statistics',
  component: StatisticsComponent,
  canActivate: [AuthGuard]
},
{
    path: 'form',
    component: MeetingFormComponent,
    children: [{
      path: '',
      loadChildren:
        () => import('./form/meeting-form/meeting-form.module')
        .then(m => m.MeetingFormModule),
      outlet: 'meeting-form'
    }]
},
{
    path: 'survey-dashboard',
    component: SurveyDashboardComponent,
},
{
    path: 'SOT',
    component: AdviceFormComponent,
    children: [{
      path: '',
      loadChildren:
        () => import('./advice-form/advice-form.module')
        .then(m => m.AdviceFormModule),
      outlet: 'advice-form'
    }]
}
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
