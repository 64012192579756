<section class="u-align-center u-clearfix u-palette-2-light-3 u-section-1" id="sec-a0ee">
  <div class="u-align-left-lg u-align-left-md u-align-left-sm u-align-left-xs u-clearfix u-sheet u-valign-middle-sm u-sheet-1">
    <div class="u-clearfix u-expanded-width u-layout-wrap sop-header-layout">
      <div class="u-layout pb-2">
        <div class="u-layout-col">
          <div class="u-container-style u-layout-cell u-shape-rectangle u-size-30 u-layout-cell-1">
            <div class="u-border-1 u-border-no-left u-border-no-right u-border-no-top u-border-palette-5-base u-container-layout u-container-layout-1 sop-header">
              <h1 class="u-text u-text-default-lg u-text-default-md u-text-default-sm u-text-default-xs u-text-1">Statistics</h1>
            </div>
          </div>
        </div>
      </div>
        <div class="u-layout">
            <div class="u-layout-col">
                <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                    <div class="u-container-layout filter-layout">
                        <a (click)="exportCSV()" class="u-input u-input-rectangle u-radius-6 u-border-1 export">Export Submissions (CSV)</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</section>

<loader *ngIf="loading"></loader>

<section *ngIf="!loading" class="u-clearfix u-palette-1-base u-section-2" style="padding-top: 20px;" id="sec-5e41">

  <div class="u-clearfix u-sheet u-sheet-1">
    <div class="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
      <div class="u-layout">
        <div class="u-layout-row" style="justify-content: space-around;">
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <div class="u-container-layout u-container-layout-1">
              <div class="u-border-1 u-container-style u-group u-radius-10 u-shape-round u-white u-group-1 statistics-card" style="height: fit-content;">
                <div class="u-container-layout u-container-layout-2" style="display: flex; ">
                  <div style="margin-left: 10px; display: flex; flex-basis: min-content;">
                    <h4 class="u-text u-text-default u-text-1">Submitted Applications</h4>
                  </div>
                  <h1 class="u-text u-text-default u-text-2" style="margin-left: 20px; margin-right: 10px;" >{{ applications }}</h1>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="submissions!.length > 0" class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <highcharts-chart
            id="container" 
            [Highcharts]="Highcharts"
            [constructorType]="chartConstructor"
            [options]="chartOptions"
            [update]="updateFlag"
            class="chart-container">
            </highcharts-chart>
          </div>
        </div>
        <div *ngIf="submissions!.length > 0" class="u-layout-row" style="justify-content: space-around; margin-top: 40px;">
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <apx-chart class="chart-container" [series]="trlChart.series" [chart]="trlChart.chart"
              [labels]="trlChart.labels" [title]="trlChart.chartTitle"></apx-chart>
          </div>
          <div class="u-container-style u-layout-cell u-left-cell u-layout-cell-1">
            <apx-chart class="chart-container" [series]="submittersChart.series" [chart]="submittersChart.chart"
              [labels]="submittersChart.labels" [title]="submittersChart.chartTitle"></apx-chart>
          </div>

        </div>

        
        <div *ngIf="submissions!.length > 0">
          <div class="main-content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="header">
                    <h4 class="title">Applications</h4>
                    <p class="category">Premium Service</p>
                  </div>
                  <div class="content table-responsive">
                      <div style="overflow-x: auto;">
                        <table class="table table-hover table-striped">
                          <thead>
                            <tr>
                                <th></th>
                                <th>Grants Agreement Number</th>
                                <th>Project Acronym</th>
                                <th>Full Project Name</th>
                                <th>Project Website</th>
                                <th>TRL</th>
    <!--                            <th>SActions</th>-->
                                <th>Name</th>
                                <th>Surname</th>
                                <th>Phone</th>
                                <th>Organisation</th>
                                <th>Organisation Type</th>
                                <th>Gender</th>
                                <th>Country</th>
                                <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let row of submissions || [] | paginate: { itemsPerPage: itemsPerPage, currentPage: p }; let i = index">
                                <td class="d-none d-md-table-cell">{{ calculateRowIndex(i) }}</td>
                                <td>{{ row['grant_agreement_number'] ?? 'Unknown' }}</td>
                                <td>{{ row['project_acronym'] ?? 'Unknown' }}</td>
                                <td>{{ row['full_project_name'] ?? 'Unknown' }}</td>
                                <td>{{ row['project_website'] ?? 'Unknown' }}</td>
                                <td>{{ row['statement'] === 'A' ? 'TRL 1-3' : row['statement'] === 'B' ? 'TRL 4-5' : 'TRL 6-9'}}</td>
    <!--                            <td>{{ getSActionsByAnswerId(row['answers']) }}</td>-->
                                <td>{{ row['name'] ?? 'Unknown' }}</td>
                                <td>{{ row['surname'] ?? 'Unknown' }}</td>
                                <td>{{ row['phone'] ?? 'Unknown' }}</td>
                                <td>{{ row['organisation'] ?? 'Unknown' }}</td>
                                <td>{{ row['organisation_type'] ?? 'Unknown' }}</td>
                                <td>{{ row['gender'] ?? 'Unknown' }}</td>
                                <td>{{ row['country'] ?? 'Unknown' }}</td>
                              <td>
                                <div class="delete-button" (click)="alertConfirmation(row)" data-toggle="modal" data-target="#deleteModal">
                                  <i class="pe-7s-trash" style="color: red;"></i>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    <pagination-controls class="custom-hover" (pageChange)="p = $event"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>  
          </div>
          
      </div>
    </div>
  </div>
  
</section>


